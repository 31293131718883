<template>
  <v-footer dark padless>
    <v-container tag="ul" class="footer__list caption">
      <li>© Olympus {{ new Date().getFullYear() }}</li>
      <li v-for="item in footerUrls" :key="item.url">
        <a v-bind="getFooterLinkProps(item)">
          {{ item.label }}
        </a>
      </li>
    </v-container>
  </v-footer>
</template>

<script>
import FooterUrls from '@/statics/urls';
import Localization from '@/helper/localization';

export default {
  name: 'AppFooter',

  computed: {
    footerUrls() {
      return [
        {
          label: this.$t('footerUrlsLabel.imprint'),
          url: Localization.buildUrl(FooterUrls.IMPRINT)
        },
        {
          label: this.$t('footerUrlsLabel.termsOfUse'),
          url: Localization.buildUrl(FooterUrls.TERMS_OF_USE)
        },
        {
          label: this.$t('footerUrlsLabel.privacyNotice'),
          url: Localization.buildUrl(FooterUrls.PRIVACY_NOTICE)
        },
        {
          label: this.$t('footerUrlsLabel.cookies'),
          url: Localization.buildUrl(FooterUrls.COOKIES)
        },
        {
          label: this.$t('footerUrlsLabel.cookiesSettings'),
          class: 'ot-sdk-show-settings'
        },
        {
          label: this.$t('footerUrlsLabel.socialMedia'),
          url: Localization.buildUrl(FooterUrls.SOCIAL_MEDIA)
        }
      ];
    }
  },
  methods: {
    getFooterLinkProps(item) {
      return {
        class: `footer__link${item.class ? ` ${item.class}` : ''}`,
        ...(item.url ? { href: item.url, target: '_blank' } : {})
      };
    }
  }
};
</script>

<style scoped lang="scss">
.footer__link {
  text-decoration: none;
  color: var(--v-grey-footer-link-base);

  &:hover {
    color: var(--v-secondary-base);
  }
}

.footer__list {
  list-style-type: none;

  > li {
    display: inline-block;
    padding: 0.5rem;
  }
}
</style>
