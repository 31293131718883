<template>
  <div>
    <h2>{{ $t('error.title') }}</h2>
    <p class="error__subline">{{ $t('error.subline') }}</p>
    <p class="error__info">{{ $t('error.info') }}</p>
    <i18n path="error.contact" tag="p" class="error__info">
      <router-link
        class="error__contact-link"
        :to="{
          query: { ...$route.query, contact: true }
        }"
      >
        {{ $t('error.customerService') }}
      </router-link>
    </i18n>

    <v-row justify="end" class="error__actions">
      <v-col cols="auto">
        <app-button color="primary" outlined :label="$t('error.refresh')" @click="onRefresh" />
      </v-col>
      <v-col cols="auto">
        <app-button color="secondary" :label="$t('error.retry')" @click="onRetry" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ErrorView',

  methods: {
    onRefresh() {
      window.location.reload();
    },
    onRetry() {
      this.$emit('retry');
    }
  }
};
</script>

<style scoped lang="scss">
.error__subline,
.error__actions {
  margin-top: 2rem;
}

.error__subline {
  @include body-2-font-style;
  color: var(--v-text-base);
}

.error__info {
  color: var(--v-text-base);
  margin-top: 1rem;

  .error__contact-link {
    display: inline-block;
  }
}
</style>
