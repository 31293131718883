<template>
  <div class="slider-container">
    <v-row class="pitch__container" no-gutters align="end">
      <v-col
        v-for="(range, index) in ranges"
        :key="range"
        class="pitch__item"
        @mousedown="currentLevel = index"
      />
    </v-row>
    <v-slider
      v-model="currentLevel"
      :tick-labels="levels"
      min="0"
      max="2"
      tick-size="0"
      :style="cssVars"
    />
  </div>
</template>

<script>
import Range, { RANGES } from '@/statics/range';

export default {
  name: 'RangeSlider',

  props: {
    value: { type: String, default: Range[2] }
  },

  computed: {
    ranges() {
      return RANGES;
    },
    levels() {
      return [
        this.$t(`procedures.range.${Range.BASIC}`),
        this.$t(`procedures.range.${Range.MID_RANGE}`),
        this.$t(`procedures.range.${Range.INNOVATION}`)
      ];
    },
    currentLevel: {
      get() {
        const rangeItemFromValue = this.ranges.findIndex((range) => range === this.value);

        return rangeItemFromValue !== -1 ? rangeItemFromValue : 1;
      },
      set(value) {
        this.$emit('input', this.ranges[value]);
      }
    },
    cssVars() {
      return {
        '--active-level': this.currentLevel
      };
    }
  },

  created() {
    this.$emit('input', this.ranges[this.currentLevel]);
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/colors.scss';

$slidableAreaToBackgroundRatio: 2/3; // 67% - ratio from most left to right tick in relation to slider background
$sliderWidthRelativeToTracksContainer: 1 / ($slidableAreaToBackgroundRatio) * 100%; // 150% - total width of slider background compared to slidable area
$sliderPartLength: 1/3 * 100%; // 33% - length between two tracks in relation to whole slider background
$slideableAreaOffset: ($sliderWidthRelativeToTracksContainer - 100%) / 2; // 25% - offset of slidable area inside slider background
$sliderPartDividerWidth: 0.25rem;
$trackHeight: 0.5rem;

.slider-container {
  padding-top: 0.5rem;
  padding-bottom: 3.5rem;
}

.v-application.theme--light .v-input {
  width: $slidableAreaToBackgroundRatio * 100%;
  margin: 0 auto;

  ::v-deep {
    .v-slider {
      margin: 0;
      @include z-index(base);
    }

    // slider thumb
    .v-slider__thumb {
      position: relative;
      left: -50%;
      width: min(7.5rem, 15vw);
      height: 1.8rem;
      cursor: pointer;
      border-radius: 0;

      &:after {
        content: '';
        background-size: 1rem;
        background-image: url('~@/assets/icons/icon-drag.svg?external');
        background-position: center center;
      }

      &:before {
        all: unset;
      }
    }

    .v-slider--horizontal {
      min-height: unset;
    }

    // slider background and track container
    .v-slider--horizontal .v-slider__track-container {
      height: $trackHeight;

      // slider background
      .v-slider__track-background {
        background-color: rgba($primary, 0.2) !important;
        left: -$slideableAreaOffset;
        width: $sliderWidthRelativeToTracksContainer !important;
        height: 100%;
        @include z-index(below);

        // highlight selected part
        &:before {
          content: '';
          background-color: rgba($primary, 0.6) !important;
          position: absolute;
          left: calc(var(--active-level) * #{$sliderPartLength}) !important;
          width: $sliderPartLength;
          height: 100%;
        }
      }

      .v-slider__track-fill.primary {
        opacity: 0;
      }

      // left white space to divide slider parts
      &:before {
        content: '';
        background-color: var(--v-white-base);
        position: absolute;
        left: calc(#{$slideableAreaOffset} - (#{$sliderPartDividerWidth} / 2));
        width: $sliderPartDividerWidth;
        height: 100%;
      }

      // right white space to divide slider parts
      &:after {
        content: '';
        background-color: var(--v-white-base);
        position: absolute;
        left: calc((100% - #{$slideableAreaOffset}) - (#{$sliderPartDividerWidth} / 2));
        width: $sliderPartDividerWidth;
        height: 100%;
      }
    }

    // labels
    .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
      top: 1.875rem;
      transform: translateX(-50%);
      max-width: 6rem;
      white-space: break-spaces;
      text-align: center;
      color: var(--v-text-base);
      line-height: normal;
    }
  }
}

.pitch__container {
  width: 100%;
  height: 100%;
  position: relative;
  top: calc(#{-$trackHeight / 2});
  display: flex;

  // left white space to divide slider parts
  &:before {
    content: '';
    background-color: var(--v-white-base);
    position: absolute;
    left: calc(1 / 3 * 100% - (#{$sliderPartDividerWidth} / 2));
    width: $sliderPartDividerWidth;
    height: 1.4rem;
  }

  // right white space to divide slider parts
  &:after {
    content: '';
    background-color: var(--v-white-base);
    position: absolute;
    left: calc((100% - (1 / 3 * 100%) - (#{$sliderPartDividerWidth} / 2)));
    width: $sliderPartDividerWidth;
    height: 2.1rem;
  }
}

.pitch__item {
  background-color: rgba($accentLight, 0.6);

  &:nth-child(1) {
    height: 0.8rem;
  }

  &:nth-child(2) {
    height: 1.4rem;
  }

  &:nth-child(3) {
    height: 2.1rem;
  }
}
</style>
