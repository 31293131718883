<template>
  <app-dialog
    :action-text="$t('saveConfiguration.sendLink')"
    :actions-hidden="submitted"
    :loading="loading"
    :query-param="queryParam"
    :value="value"
    action-icon="$iconSend"
    title-icon="$iconSave"
    v-bind="$attrs"
    @submit="onSubmit"
    v-on="$listeners"
  >
    <template v-slot:title>
      <span class="subtitle-1 font-weight-bold">{{ $t('saveConfiguration.subtitle') }}</span>
      <h2>{{ $t('saveConfiguration.title') }}</h2>
    </template>

    <div v-if="submitted" class="success__container">
      <h2>
        <icon-check class="success__icon" />
        {{ $t('saveConfiguration.successHeadline') }}
      </h2>
      <h3 class="area__subline" v-html="$t('saveConfiguration.successSubline')"></h3>

      <div class="save-configuration__link-container">
        <div class="save-configuration__link-wrapper">
          <a :href="linkToConfiguration" target="_blank" class="save-configuration__link">
            {{ linkToConfiguration }}
          </a>
        </div>
        <app-button
          outlined
          icon="$iconDownload"
          :label="$t('saveConfiguration.copyLinkToClipboard')"
          @click="onCopyLinkToClipboard"
        />
      </div>
      <app-button
        class="success__button"
        color="secondary"
        :label="$t('contact.backToConfiguration')"
        @click="onClose"
      />
    </div>

    <template v-else>
      <h3 class="area__subline primary--text">{{ $t('saveConfiguration.subline') }}</h3>

      <v-row class="flex-column" align="start" justify="center" justify-sm="space-between">
        <v-col>
          <app-alert v-if="error" color="error" class="form__alert">
            {{ $t('error.alert') }}
          </app-alert>

          <ValidationObserver ref="emailForm" tag="form" @submit.prevent="onSubmit">
            <v-row>
              <v-col cols="12" sm="8" md="6">
                <app-text-field
                  id="email"
                  v-model="contactData.customerEmail"
                  :name="$t('formData.email')"
                  :headline="$t('saveConfiguration.emailHeadline')"
                  :placeholder="$t('contactForm.email')"
                  rules="required|email"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="6">
                <span
                  class="caption text--text"
                  v-html="$t('contactForm.privacy', { link: privacyLink })"
                ></span>
              </v-col>
              <v-col cols="4" md="6">
                <span class="caption text--text">
                  {{ $t('contactForm.checkboxRequired') }}
                </span>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppAlert from '@/components/base/AppAlert';
import AppTextField from '@/components/base/AppTextField';
import FooterUrls from '@/statics/urls';
import IconCheck from '@/assets/icons/icon-check.svg';
import Localization from '@/helper/localization';
import RouteName from '@/statics/routeName';
import StepsMixin from '@/mixins/StepsMixin';
import Tracking from '@/helper/tracking';
import showSnackbar from '@/helper/showSnackbar';
import { GlobalDialogQuery } from '@/statics/dialogQueries';
import { mapActions } from 'vuex';

export default {
  name: 'SaveConfigurationDialog',

  components: { AppAlert, AppTextField, IconCheck },

  mixins: [StepsMixin],

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    error: false,
    loading: false,
    submitted: false,
    contactData: {
      customerEmail: ''
    },
    linkToConfiguration: '',
    queryParam: GlobalDialogQuery.SAVE_CONFIGURATION
  }),

  computed: {
    privacyLink() {
      return `
        <a href="${Localization.buildUrl(
          FooterUrls.PRIVACY_NOTICE
        )}" target="_blank" class="primary--text">${this.$t('footerUrlsLabel.privacyNotice')}</a>
      `;
    }
  },

  watch: {
    value(value) {
      if (!value) {
        this.resetForm();
      }
    }
  },

  methods: {
    ...mapActions(['saveConfiguration']),

    async onSubmit() {
      this.error = false;
      if (!(await this.$refs.emailForm.validate())) {
        this.scrollToFaultyInput();
        return;
      }

      this.loading = true;
      const { webCode, error } = await this.saveConfiguration({
        contactData: this.contactData,
        step: this.currentStep
      });
      this.loading = false;

      if (error) {
        this.error = true;
        return;
      }

      this.linkToConfiguration =
        window.location.origin +
        this.$router.resolve({
          name: RouteName.RESTORE_CONFIGURATION,
          params: { configurationId: webCode }
        }).href;

      this.submitted = true;
      Tracking.trackSuccess();
    },

    onClose() {
      this.$emit('input', false);
    },

    async onCopyLinkToClipboard() {
      try {
        await navigator.clipboard.writeText(this.linkToConfiguration);

        showSnackbar({
          text: this.$t('saveConfiguration.writeClipboardSuccess'),
          color: 'success',
          timeout: 2000
        });
      } catch (err) {
        showSnackbar({
          text: this.$t('saveConfiguration.writeClipboardFailed')
        });
      }
    },

    scrollToFaultyInput() {
      this.$vuetify.goTo(document.querySelector('.input--error'), {
        duration: 500,
        offset: 50,
        easing: 'easeInOutCubic',
        container: '.dialog__content-container'
      });
    },

    resetForm() {
      this.error = false;
      this.loading = false;
      this.submitted = false;
      this.contactData = {
        customerEmail: ''
      };
      this.linkToConfiguration = '';
    }
  }
};
</script>

<style scoped lang="scss">
.area__subline {
  margin: 1.5rem 0 2rem;
}

.form__alert {
  margin-bottom: 1.5rem;
}

.success__container {
  color: var(--v-primary-base);

  @media #{map-get($display-breakpoints, 'xs-only')} {
    text-align: center;
  }
}

.success__icon {
  color: var(--v-success-base);
  height: map-deep-get($headings, 'h2', 'size');

  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: block;
    margin: 2rem auto 2rem auto;
    height: 4rem;
  }
}

.success__button {
  float: right;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    float: unset;
  }
}

.save-configuration__link-container {
  margin: 2.5rem 0;
}

.save-configuration__link-wrapper {
  display: block;
  background-color: var(--v-text-base);
  color: var(--v-white-base);
  padding: 1rem;
  margin: 1.5rem 0;
}

.save-configuration__link {
  color: inherit;
}
</style>
