<template>
  <v-radio-group v-model="selected" class="body-2" hide-details v-bind="$attrs">
    <slot>
      <v-radio
        v-for="(item, index) in items"
        :key="index"
        :label="item.label"
        :value="item.value"
      />
    </slot>
  </v-radio-group>
</template>

<script>
export default {
  name: 'AppRadio',

  props: {
    items: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    value: { type: [String, Number, Object, Array, Boolean], required: true }
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-application .v-input--radio-group.theme--light {
  margin-top: 0;
  padding-top: 0;

  ::v-deep {
    .v-radio {
      align-items: flex-start;
    }

    .theme--light.v-label {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      top: $fontVerticalOffsetBody2;
    }

    .theme--light.v-icon {
      color: inherit;
    }
  }

  &.v-input--is-disabled {
    color: var(--v-grey-disabled-base);

    ::v-deep .v-icon {
      color: var(--v-grey-disabled-base) !important;
    }
  }
}
</style>
