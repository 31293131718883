<template>
  <v-select
    v-model="selection"
    :append-icon="selection && selection.length > 0 ? '' : '$iconArrowDown'"
    :class="{ 'select--is-active': selection && selection.length > 0 }"
    :items="translatedItems"
    :label="translatedLabel"
    :menu-props="{ offsetY: true, contentClass: 'select__menu' }"
    :multiple="multiple"
    clearable
    color="primary"
    dense
    hide-details
    item-text="label"
    item-value="value"
    outlined
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #item="{ item }">
      <app-checkbox v-if="multiple" :label="item.label" :value="selection.includes(item.value)" />
      <template v-else>{{ item.label }}</template>
    </template>
  </v-select>
</template>

<script>
import Localization from '@/helper/localization';

export default {
  name: 'AppFilter',

  props: {
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Number, String],
      default: ''
    }
  },

  computed: {
    selection: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    translatedItems() {
      return this.items.map((item) => ({
        ...item,
        label:
          this.$te(item.label) || this.$te(item.label, Localization.defaultLocale)
            ? this.$t(item.label)
            : item.label
      }));
    },
    translatedLabel() {
      return this.$t(this.label);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/colors.scss';

.v-application .v-input.theme--light.v-text-field--outlined {
  border-radius: 0;
  color: var(--v-primary-base);

  &:not(.v-input--is-focused):not(.select--is-active) ::v-deep .v-input__slot {
    filter: brightness(70%);
    background-color: rgba($primary, 0.2);
  }

  ::v-deep {
    .v-input__slot {
      height: 3rem;
    }

    .v-input__control > .v-input__slot fieldset {
      border-color: var(--v-primary-base);
    }

    .v-label,
    .v-icon {
      color: inherit;
    }

    .v-label {
      @include body-font-style;
    }

    .v-label:not(.v-label--active),
    .v-input__append-inner {
      position: static !important;
      padding: 0.25rem 0;
      margin-top: 0;
      display: flex;
      align-items: center;
      height: 100%;

      &.v-label {
        margin-top: $fontVerticalOffsetBody2;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .v-select__selections {
      color: inherit;
      flex-wrap: nowrap;
      padding: 0.25rem 0;
      margin-top: $fontVerticalOffsetBody2;
      height: 100%;
    }
  }
}
</style>
