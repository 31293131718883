<template>
  <div>
    <label class="text--text" :for="id" v-html="headlineWithAdditional"></label>

    <ValidationProvider v-slot="{ errors }" :ref="id" :name="name" :rules="rules" mode="eager">
      <v-textarea
        :id="id"
        v-model="text"
        :class="{ 'input--error': errors.length > 0 }"
        :error-messages="errors"
        :label="`${placeholder}${additional}`"
        flat
        outlined
        solo
        v-bind="$attrs"
        v-on="$listeners"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppTextArea',

  mixins: [FormValidationMixin],

  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/formInput.scss';
</style>
