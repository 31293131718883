import { flattenArray } from '@/helper/flattenArray';

/**
 * filters property values of preset articles and scopes
 * only the first match in article is added to the returned list
 * @param properties {Array} - list of article properties to look for, first match gets returned
 * @param preset {Object} - current preset configuration
 * @returns {Array} - list of first matching property value of the preset articles
 */
export const filterArticlePropertiesInPreset = (properties, preset) =>
  flattenArray(
    Object.values(preset).map((trolleyData) =>
      Object.values({
        ...(trolleyData?.article ?? {}),
        ...(trolleyData?.scope ?? {})
      }).map((zoneData) =>
        (Object.values(zoneData) || []).map((articlesInZone) => {
          if (!articlesInZone) {
            return;
          }

          if (Array.isArray(articlesInZone)) {
            return articlesInZone.map((article) => {
              return getFirstMatchingPropertyValue(article, properties);
            });
          }

          return getFirstMatchingPropertyValue(articlesInZone, properties);
        })
      )
    )
  ).filter((article) => !!article);

const getFirstMatchingPropertyValue = (object, properties) =>
  object[properties.find((property) => object[property])];
