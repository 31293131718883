<template>
  <v-snackbar v-model="snackbar" class="app__snackbar" bottom :timeout="timeout" light>
    <app-alert :color="color" :icon="icon">
      {{ text }}
    </app-alert>
    <template #action>
      <v-icon :color="color" @click="snackbar = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import AppAlert from '@/components/base/AppAlert';

export default {
  name: 'AppSnackbar',

  components: { AppAlert },

  data: () => ({
    snackbar: false,
    text: '',
    color: '',
    icon: '',
    timeout: -1
  }),

  created() {
    // listen to global event
    this.$eventHub.$on('snackbar', this.getText);
  },

  beforeDestroy() {
    this.$eventHub.$off('snackbar');
  },

  methods: {
    getText({ text, color, icon, timeout }) {
      this.text = text;
      this.color = color;
      this.icon = icon;
      this.timeout = timeout;
      this.snackbar = true;
    }
  }
};
</script>

<style scoped lang="scss">
.app__snackbar {
  position: fixed;
  bottom: 4rem;
}

::v-deep {
  .v-alert {
    padding-right: 3rem;
  }

  .v-snack__content {
    padding: 0;
  }

  .v-snack__action {
    position: absolute;
    right: 0.5rem;
  }
}
</style>
