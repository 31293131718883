import Vue from 'vue';
import i18n from '@/plugins/i18n';
import { LocaleCode, LOCALES } from '@/statics/locale';

const Localization = {
  get defaultLocale() {
    return process.env.VUE_APP_I18N_LOCALE;
  },

  get supportedLocales() {
    return LOCALES.map((locale) => locale.code);
  },

  get currentLocale() {
    return i18n.locale;
  },

  set currentLocale(locale) {
    i18n.locale = locale;
  },

  initiated: false,

  getUserSupportedLocale() {
    const userLocale = window.navigator.language || Localization.defaultLocale;
    const userPrimaryLocaleCode = userLocale.split('-')[0];

    if (Localization.isLocaleSupported(userLocale)) {
      return userLocale;
    }

    if (Localization.isLocaleSupported(userPrimaryLocaleCode)) {
      return userPrimaryLocaleCode;
    }

    return Localization.defaultLocale;
  },

  isLocaleSupported(locale) {
    return Localization.supportedLocales.includes(locale);
  },

  changeLocale(locale) {
    if (!Localization.isLocaleSupported(locale)) {
      return Promise.reject(new Error(`Locale ${locale} not supported`));
    }

    if (i18n.locale === locale) {
      return Promise.resolve(locale);
    }

    return Localization.loadTranslationsFile(locale).then((messages) => {
      Localization.currentLocale = locale;
      i18n.setLocaleMessage(locale, messages.default || messages);

      if (!Localization.initiated) {
        Localization.initiated = true;
      }

      Vue.prototype.$eventHub.$emit('locale-changed', locale);
    });
  },

  loadTranslationsFile(locale) {
    return import(`@/translations/${locale}.js`);
  },

  buildUrl(url, blackList = [LocaleCode.RU, LocaleCode.EN]) {
    if (blackList.includes(i18n.locale)) {
      return Localization.getDefaultUrl(url);
    }

    const locale = LOCALES.find((locale) => locale.code === i18n.locale);

    if (!locale) {
      return Localization.getDefaultUrl(url);
    }

    return url.replace('LANG', locale.primaryLocaleCode).replace('DOMAIN', locale.domain);
  },

  getDefaultUrl(url) {
    return url.replace('LANG', LocaleCode.EN).replace('.DOMAIN', '-europa.com');
  }
};

export default Localization;
