<template>
  <v-row justify="center">
    <v-col cols="12" sm="7" md="9">
      <h1 class="step__headline">{{ $t('gettingStarted.startScreenHeadline') }}</h1>
      <div class="step__area">
        <h3>{{ $t('gettingStarted.medicalSpecialityHeadline') }}</h3>
        <app-radio
          row
          mandatory
          :items="medicalSpecialityItems"
          :value="medicalSpeciality"
          @input="setMedicalSpeciality($event)"
        />
      </div>

      <div class="step__area">
        <h3>{{ $t('gettingStarted.trolleyProcedureTypesHeadline') }}</h3>
        <app-radio
          row
          mandatory
          :items="procedureTypeItems"
          :value="selectedProcedureTypeOption"
          @input="setProcedureTypeOption($event)"
        />
      </div>
    </v-col>

    <v-col cols="5" md="3" class="d-flex align-center">
      <v-img src="@/assets/trolley.png" />
    </v-col>
  </v-row>
</template>

<script>
import { MEDICAL_SPECIALITIES } from '@/statics/medicalSpeciality';
import { PROCEDURE_TYPE_SELECT_OPTIONS } from '@/statics/procedureType';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'StepOneGettingStarted',

  computed: {
    ...mapState(['medicalSpeciality', 'selectedProcedureTypeOption']),
    medicalSpecialityItems() {
      return MEDICAL_SPECIALITIES.map((value) => ({
        label: this.$t(`gettingStarted.medicalSpeciality.${value}`),
        value
      }));
    },
    procedureTypeItems() {
      return PROCEDURE_TYPE_SELECT_OPTIONS.map((value) => ({
        label: this.$t(`gettingStarted.procedureTypeSelectOptions.${value}`),
        value
      }));
    }
  },

  methods: {
    ...mapMutations(['setMedicalSpeciality', 'setProcedureTypeOption'])
  }
};
</script>

<style scoped lang="scss">
.step__area ::v-deep .v-input--radio-group__input {
  .v-radio {
    flex: 0 0 50%;
    margin-right: 0;
    padding-right: 0.5rem;
    margin-top: 1.5rem;
  }
}
</style>
