import { Trolley } from '@/statics/trolley';

export const buildConfigurationData = (state, getters) => {
  const currentAccessories = { ...state.preset[Trolley.MAIN].accessory };

  const updatedAccessories = Object.entries(currentAccessories).filter((accessory) =>
    state.selectedAccessoriesProductCodes.includes(accessory[1].productCode)
  );

  const filterData = {
    medicalSpeciality: state.medicalSpeciality,
    type: state.selectedProcedureTypeOption,
    priceRange: state.range,
    procedures: getters.selectedProcedures
  };

  return {
    contactData: { ...state.contactData },
    filterData: filterData,
    preset: {
      ...state.preset,
      [Trolley.MAIN]: {
        ...state.preset[Trolley.MAIN],
        accessory: Object.fromEntries(updatedAccessories)
      }
    }
  };
};
