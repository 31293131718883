<template>
  <v-sheet :id="id" elevation="6" class="bottom-navigation">
    <v-container>
      <v-row class="actions" align="center" justify="space-between" no-gutters>
        <v-col v-if="$vuetify.breakpoint.smAndUp" class="actions__column" cols="auto" sm="4">
          <app-button
            :class="{ hidden: currentStep === 1 && !navOnly }"
            icon="$iconAngleLeft"
            outlined
            :label="$t('actions.back')"
            @click="$emit('back')"
          />
        </v-col>
        <v-col v-if="!navOnly" class="actions__column" cols="auto" sm="4">
          <app-button
            v-if="enableSaveConfiguration"
            outlined
            icon="$iconSave"
            :label="$vuetify.breakpoint.smAndUp ? $t('actions.save') : ''"
            @click="showSaveConfigurationDialog = true"
          />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndUp && !navOnly" cols="auto">
          <app-button icon="$iconMail" outlined @click="showContactDialog = true" />
        </v-col>
        <v-spacer />
        <v-col class="actions__column" cols="auto" sm="4">
          <app-button
            :disabled="disabled || loading"
            :icon="actionIcon"
            :label="actionText || $t('actions.continue')"
            :loading="loading"
            color="secondary"
            @click="$emit('next')"
          />
          <app-button
            v-if="$vuetify.breakpoint.smAndUp && !navOnly"
            icon="$iconMail"
            outlined
            @click="showContactDialog = true"
          />
        </v-col>
      </v-row>
    </v-container>

    <contact-dialog v-if="!navOnly" v-model="showContactDialog" :nav-only="navOnly" />
    <save-configuration-dialog
      v-if="enableSaveConfiguration"
      v-model="showSaveConfigurationDialog"
      :nav-only="navOnly"
    />
  </v-sheet>
</template>

<script>
import ContactDialog from '@/components/ContactDialog';
import SaveConfigurationDialog from '@/components/SaveConfigurationDialog';
import StepsMixin from '@/mixins/StepsMixin';

export default {
  name: 'ActionBar',

  components: { ContactDialog, SaveConfigurationDialog },

  mixins: [StepsMixin],

  props: {
    actionIcon: {
      type: String,
      default: ''
    },
    actionText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    navOnly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    showContactDialog: false,
    showSaveConfigurationDialog: false
  }),

  computed: {
    enableSaveConfiguration() {
      return this.currentStep >= 3 && !this.navOnly;
    }
  }
};
</script>

<style scoped lang="scss">
.bottom-navigation {
  background-color: var(--v-white-base);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  @include z-index(top);

  .container {
    padding: 0.25rem 0.75rem;
  }

  .actions {
    button {
      margin: 0.75rem;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        margin: 0.25rem;
      }

      &.hidden {
        opacity: 0;
        user-select: none;
        cursor: default;
      }
    }

    .actions__column {
      text-align: center;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        flex: 0 0 auto;
      }

      &:last-of-type {
        text-align: right;
        display: flex;
        justify-content: flex-end;
      }

      &:first-of-type {
        text-align: left;
      }
    }
  }
}
</style>
