import MedicalSpeciality from '@/statics/medicalSpeciality';
import Range from '@/statics/range';
import Trolley from '@/statics/trolley';
import { Feature, GIProcedureArea, LowerGILength, RespiratoryType } from '@/statics/scopeFilter';
import { LocaleCode } from '@/statics/locale';
import { ProcedureType, ProcedureTypeSelectOptions } from '@/statics/procedureType';

export default {
  meta: {
    /* same as meta infos in index.html and index_en.html */
    title: 'Endoscopy Tower System - Configuration in 5 Steps',
    description:
      'Select the focus of your procedures, optimize according to your needs and get your individual endoscopy tower configuration via email.'
  },
  locale: {
    [LocaleCode.DE]: 'German',
    [LocaleCode.EN]: 'English (US)',
    [LocaleCode.EN_GB]: 'English (UK)',
    [LocaleCode.ES]: 'Spanish',
    [LocaleCode.IT]: 'Italian',
    [LocaleCode.NL]: 'Dutch',
    [LocaleCode.RU]: 'Russian'
  },
  step: 'Step',
  progressBarTitles: {
    gettingStarted: '@:gettingStarted.title',
    procedures: '@:procedures.title',
    optimize: '@:optimize.title',
    accessories: '@:accessories.title',
    request: '@:request.title'
  },
  loadingConfiguration: 'Loading configuration...',
  gettingStarted: {
    title: 'Getting Started',
    startScreenHeadline: 'Configure Your New Endoscopy Tower in Just a Few Steps',
    medicalSpecialityHeadline: 'Please Choose a Medical Specialty',
    medicalSpeciality: {
      [MedicalSpeciality.GASTROENTEROLOGY]: 'Gastroenterology',
      [MedicalSpeciality.PULMONOLOGY]: 'Pulmonology'
    },
    trolleyProcedureTypesHeadline: 'Select the Focus of Your Procedures',
    procedureTypeSelectOptions: {
      [ProcedureTypeSelectOptions.ADVANCED]: 'Advanced procedures and options',
      [ProcedureTypeSelectOptions.GENERAL]: 'General procedures'
    }
  },
  procedures: {
    title: 'Procedures',
    headline: 'Please Choose Your Procedures and the Focus of Your Preferred Setup',
    procedureRequest: 'For Which Procedures Are You Planning to Invest in New Equipment?',
    procedureTypes: {
      [ProcedureType.DIAGNOSTIC]: 'General Procedures',
      [ProcedureType.THERAPEUTIC]: 'Advanced Procedures and Options'
    },
    errorNoProcedureSelected: 'Please select one of the procedures below first.',
    resetFilter: 'Reset selection',
    hasRequirementsInfo: 'Please Select at Least One of the Procedures First:',
    isRequirementInfo: 'Please Discard These Selected Procedures First:',
    rangeRequest: 'Select the Focus of Your Preferred Setup',
    range: {
      [Range.BASIC]: 'Best price',
      [Range.MID_RANGE]: 'Price and innovation',
      [Range.INNOVATION]: 'Best performance'
    }
  },
  optimize: {
    title: 'Optimize',
    trolleys: {
      [Trolley.MAIN]: 'Endo Tower',
      [Trolley.GHOST]: 'Additional Items',
      [Trolley.ESG]: 'HF Generators'
    },
    trolleyTitles: {
      [Trolley.MAIN]: 'Your Endo Tower',
      [Trolley.GHOST]: `@:optimize.trolleys.${Trolley.GHOST}`,
      [Trolley.ESG]: `@:optimize.trolleys.${Trolley.ESG}`
    },
    empty: 'Empty',
    addComponent: 'Add Component',
    scopeGuide: 'ScopeGuide',
    zones: {
      M0: 'Monitors',
      M1: 'Artificial intelligence',
      M2: 'Video processors',
      M3: 'Ultrasound processor',
      M4: 'Pumps',
      M5: 'CO2 insufflator',
      M6: 'HF generators',
      M7: 'Endoscopes'
    },
    selectedCounted: '{count} selected',
    viewESG: 'HF generators',
    ghostDescription:
      'If not all selected items physically fit on your endoscopy tower, additional items are displayed in this section (for visualization purposes only).',
    esgDescription: 'The ESG-300 comes with the TC-E300 trolley (and optional Argon Plasma Unit).',
    supportHintMedicalSpeciality: '{supported} only'
  },
  changeArticle: {
    subline: 'Your Individual Configuration',
    headline: 'Change Products',
    showDetails: 'Show details',
    scopesSelectedCounted: '{count} scope selected |{count} scopes selected',
    currentlySelected: 'Currently selected:',
    productCode: 'Product Code',
    hasRequirementsInfo: 'Please Select One of These Products First:',
    isRequirementInfo: 'Please Discard These Selected Products First:',
    technicalSpecifications: 'Technical Specifications',
    noArticlesAvailable: 'No articles compatible with currently selected video processor.',
    noScopesAvailable:
      'No scopes of this selection compatible with currently selected video processor.',
    scopeGuideSubline: '3D-Image-Navigation System',
    scopeGuideIntro: 'How Our 3D-Image-Navigation System ScopeGuide Works',
    scopeGuideSummary:
      'ScopeGuide provides a real-time 3D image of the position and configuration of the endoscope inside the colon.',
    scopeGuideDescription:
      'Electromagnetic coils incorporated along the length of the dedicated scope’s insertion tube generate a pulsed low-intensity magnetic field that is picked up by the receiver dish. <br /> The magnetic pulses are used to calculate the precise position and orientation of the insertion tube to generate a three-dimensional image on the monitor.',
    scopeGuideIncludedItems: 'Includes the Following Items:',
    notVisualized: 'Not visualized in trolley configuration.'
  },
  scopeFilter: {
    area: {
      label: 'Area',
      items: {
        [GIProcedureArea.UPPER_GI]: 'Upper GI',
        [GIProcedureArea.LOWER_GI]: 'Lower GI',
        [GIProcedureArea.ERCP]: 'ERCP',
        [GIProcedureArea.ENTEROSCOPY]: 'Enteroscopy',
        [GIProcedureArea.ULTRASOUND]: 'Ultrasound'
      }
    },
    length: {
      label: 'Scope Length',
      items: {
        [LowerGILength.INTERMEDIATE]: 'Intermediate',
        [LowerGILength.LONG]: 'Long'
      }
    },
    respiratoryType: {
      label: 'Type',
      items: {
        [RespiratoryType.BRONCHOSCOPE]: 'Bronchoscope',
        [RespiratoryType.MINIPROBE]: 'Radial EBUS',
        [RespiratoryType.PLEURASCOPE]: 'Pleuroscope',
        [RespiratoryType.ULTRASOUND]: 'EBUS-TBNA'
      }
    },
    features: {
      label: 'Features',
      items: {
        [Feature.WATER_JET]: 'Water Jet',
        [Feature.HQ]: 'HQ',
        [Feature.TRANSNASAL]: 'Transnasal',
        [Feature.PCF]: 'PCF'
      }
    },
    series: {
      label: 'Scope Series'
    }
  },
  accessories: {
    title: 'Accessories',
    headline: 'For a Fully Equipped Endoscopy System, We Recommend the Following Accessories'
  },
  request: {
    title: 'Send Request',
    subline: 'Your Selection',
    headline: 'We Will Contact You with an Individual Offer',
    areaTrolley: 'Endoscopy tower',
    areaAccessories: 'Accessories'
  },
  success: {
    title: 'Request submitted',
    headline: 'Thank you very much.',
    subline:
      'Your request for your endoscopy tower configuration has been sent successfully. Please note it may take up to five minutes until you receive your email.<br /> We will contact you shortly.',
    secondSubline: 'Find out more about EVIS X1',
    buttonNew: 'Start a new configuration',
    buttonDetail: 'Find out more',
    detailAssetPath: '/assets/successScreen_bottom_en.png',
    detailLink:
      'https://www.olympus-europa.com/medical/en/Products-and-Solutions/Products/Product/EVIS-X1.html'
  },
  contact: {
    subline: 'Contact',
    headline: 'Get Support',
    info: 'Need support with configuring your endoscopy tower? Get in touch and we will contact you shortly!',
    success: 'Your request has been sent successfully.<br />We will contact you shortly.',
    customerQuestionHeadline: 'Request',
    backToConfiguration: 'Back to configuration'
  },
  saveConfiguration: {
    title: 'Continue Later',
    subtitle: 'Save for Later',
    subline: 'Enter your email address, and we will send you a link to resume your configuration.',
    sendLink: 'Send me the link',
    copyLinkToClipboard: 'Copy this link to clipboard',
    emailHeadline: 'What is your email?',
    writeClipboardSuccess: 'Copied to clipboard',
    writeClipboardFailed: 'Copy to clipboard failed',
    successHeadline: 'Configuration saved',
    successSubline:
      'Your configuration has been saved successfully.<br /> Continue anytime by following the link below or from the email we will send you shortly.'
  },
  footerUrlsLabel: {
    imprint: 'Imprint',
    termsOfUse: 'Terms of Use',
    privacyNotice: 'Privacy Notice',
    cookies: 'Cookies',
    cookiesSettings: 'Cookies Settings',
    socialMedia: 'Social Media'
  },
  contactForm: {
    country: 'Country',
    countryHeadline: 'Select your country',
    customerName: 'First and last name',
    customerNameHeadline: 'What is your name?',
    email: 'Email address',
    emailHeadline: 'What is your email?',
    customerPhoneNumber: 'Phone Number',
    customerPhoneNumberHeadline: 'What is your phone number?',
    customerCompany: 'Your hospital/organization',
    customerCompanyHeadline: 'Which hospital/organization do you work for?',
    customerQuestion: 'Your message',
    customerQuestionHeadline: 'Is there anything else you would like to tell us?',
    privacy:
      'Olympus respects your privacy. Please see our {link} for information on how we process your personal data for replying to your query.',
    checkboxRequired: '*Required'
  },
  actions: {
    back: 'Back',
    confirm: 'Confirm',
    continue: 'Continue',
    no: 'No',
    save: 'Save',
    select: 'Select',
    selectNothing: 'Select nothing',
    selected: 'Selected',
    selectedNothing: 'Nothing selected',
    sendRequest: 'Send Request',
    submit: 'Send',
    yes: 'Yes'
  },
  error: {
    alert: 'Something went wrong. Please try again later.',
    title: 'Something Went Wrong',
    subline: 'Many thanks for your interest in Olympus devices!',
    info: 'Unfortunately, an error occurred. Please wait a few seconds and retry your last action or refresh this page.',
    contact: 'Get in touch with our {0}.',
    customerService: 'customer service',
    refresh: 'Refresh Page',
    retry: 'Retry'
  },
  formData: {
    customerName: 'Name',
    customerCompany: '@:contactForm.customerCompany',
    country: 'Country',
    email: 'Email',
    customerPhoneNumber: 'Phone',
    customerQuestion: 'Message',
    optional: 'optional'
  },
  countryOptions: [
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'Angola', value: 'AO' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Bosnia & Hercegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Egypt', value: 'EG' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Greece', value: 'GR' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'Iran', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Ivory Coast', value: 'CI' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Libya', value: 'LY' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Macedonia', value: 'MK' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Palestine', value: 'PS' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Republic of Congo', value: 'CG' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russia', value: 'RU' },
    { label: 'Rwanda', value: 'RW' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia & Montenegro', value: 'RS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Sudan', value: 'SS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Swasiland', value: 'SZ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syria', value: 'SY' },
    { label: 'Tanzania', value: 'TZ' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tunesia', value: 'TN' },
    { label: 'Turkey', value: 'TC' },
    { label: 'UK & Ireland', value: 'GB' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' }
  ]
};
