import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import IconAngleDown from '@/assets/icons/icon-angle-down.svg';
import IconAngleLeft from '@/assets/icons/icon-angle-left.svg';
import IconArrowDown from '@/assets/icons/icon-arrow-down.svg';
import IconArrowLeft from '@/assets/icons/icon-arrow-left.svg';
import IconArrowRight from '@/assets/icons/icon-arrow-right.svg';
import IconDownload from '@/assets/icons/icon-download.svg';
import IconInfo from '@/assets/icons/icon-info.svg';
import IconMail from '@/assets/icons/icon-mail.svg';
import IconSave from '@/assets/icons/icon-save.svg';
import IconSend from '@/assets/icons/icon-send.svg';

import colors from '@/styles/colors.scss';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        ...colors
      }
    }
  },
  /**
   * register custom icon components for usage in v-icon etc.,
   * accessible via $ (e.g. $iconSave) instead of "mdi-" prefix
   * @see {@link https://vuetifyjs.com/en/features/icon-fonts/#reusable-custom-icons}
   */
  icons: {
    values: {
      iconAngleDown: {
        component: IconAngleDown
      },
      iconAngleLeft: {
        component: IconAngleLeft
      },
      iconArrowDown: {
        component: IconArrowDown
      },
      iconArrowLeft: {
        component: IconArrowLeft
      },
      iconArrowRight: {
        component: IconArrowRight
      },
      iconDownload: {
        component: IconDownload
      },
      iconInfo: {
        component: IconInfo
      },
      iconMail: {
        component: IconMail
      },
      iconSave: {
        component: IconSave
      },
      iconSend: {
        component: IconSend
      }
    }
  }
});
