export const flattenArray = (array) => {
  return array.reduce((flatList, entry) => {
    if (Array.isArray(entry)) {
      return flatList.concat(flattenArray(entry));
    }

    return flatList.concat(entry);
  }, []);
};

export default flattenArray;
