<template>
  <section :class="{ background: true, 'background--accent': currentStep === 1 }">
    <v-container class="step__container">
      <v-slide-x-reverse-transition hide-on-leave group>
        <div
          v-if="currentStepConfig.title && !loading && !error"
          :key="`step__title-${currentStep}`"
          class="step__title subtitle-1"
        >
          {{ $t('step') }} {{ currentStep }} / {{ $t(currentStepConfig.title) }}
        </div>

        <ValidationObserver key="configuratorForm" ref="form" tag="form" @submit.prevent="">
          <app-spinner v-if="loading" key="spinner" class="step__spinner" />
          <error-view v-else-if="error" class="step__error" @retry="onRetryAfterError" />
          <component
            :is="currentStepConfig.component"
            v-else
            :key="`step__component-${currentStep}`"
            v-bind="currentStepConfig.props"
            v-on="currentStepConfig.listeners"
          />
        </ValidationObserver>

        <action-bar
          id="bottom-navigation"
          key="stepper__actions"
          :action-text="
            currentStepConfig.step === steps.length ? $t('actions.submit') : $t('actions.continue')
          "
          :loading="loading"
          :disabled="error"
          @back="onBack"
          @next="onNext"
        />
      </v-slide-x-reverse-transition>
    </v-container>
  </section>
</template>

<script>
import ActionBar from '@/components/ActionBar';
import ErrorView from '@/components/ErrorView';
import RouteName from '@/statics/routeName';
import StepFiveRequest from '@/components/steps/5/StepFiveRequest';
import StepFourAccessories from '@/components/steps/4/StepFourAccessories';
import StepOneGettingStarted from '@/components/steps/1/StepOneGettingStarted';
import StepThreeOptimize from '@/components/steps/3/StepThreeOptimize';
import StepTwoProcedures from '@/components/steps/2/StepTwoProcedures';
import StepperProgressBar from '@/components/StepperProgressBar';
import StepsMixin from '@/mixins/StepsMixin';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'Configurator',

  components: { StepperProgressBar, ActionBar, ErrorView },

  mixins: [StepsMixin],

  data: () => ({
    loading: false,
    dataInitiated: false,
    submitFailed: false
  }),

  computed: {
    ...mapState(['preset']),
    ...mapState(['formIsValid', 'error']),
    steps() {
      return [
        {
          step: 1,
          title: 'gettingStarted.title',
          component: StepOneGettingStarted,
          onNext: this.waitUntilDataInitiated,
          onError: this.initiateData
        },
        {
          step: 2,
          title: 'procedures.title',
          component: StepTwoProcedures,
          onNext: this.getPreset,
          onError: this.initiateData
        },
        {
          step: 3,
          title: '',
          component: StepThreeOptimize
        },
        {
          step: 4,
          title: 'accessories.title',
          component: StepFourAccessories
        },
        {
          step: 5,
          title: 'request.title',
          component: StepFiveRequest,
          onNext: this.sendConfiguration,
          props: {
            error: this.submitFailed
          }
        }
      ];
    },
    currentStepConfig() {
      return Object.values(this.steps)[this.currentStep - 1];
    }
  },

  watch: {
    currentStep() {
      // reset invalid status after go-back
      if (!this.formIsValid) {
        this.checkFormIsValid();
      }
    }
  },

  created() {
    if (this.currentStep !== 1 && !this.preset) {
      this.currentStep = 1;
    }

    this.initiateData();
  },

  mounted() {
    this.initiateLocaleChangeListener();
  },

  methods: {
    ...mapActions(['fetchProcedures', 'fetchPreset', 'fetchZones', 'submitConfiguration']),
    ...mapMutations(['setFormIsValid', 'setError']),

    async initiateData() {
      this.resetError();
      await Promise.all([this.fetchProcedures(), this.fetchZones()]);

      if (this.error) {
        return;
      }

      this.dataInitiated = true;
    },

    initiateLocaleChangeListener() {
      this.$eventHub.$on('locale-changed', () => {
        this.initiateData();
      });

      this.$on('hook:beforeDestroy', () => {
        this.$eventHub.$off('locale-changed');
      });
    },

    async onBack() {
      this.error && this.resetError();
      this.currentStep > 1 && this.currentStep--;
    },

    async onNext() {
      if (!(await this.checkFormIsValid())) {
        this.scrollToFaultyInput();
        return;
      }

      if (this.currentStepConfig.onNext) {
        return this.currentStepConfig.onNext();
      }

      this.goToNextStep();
    },

    goToNextStep() {
      this.currentStep < this.steps.length && this.currentStep++;
    },

    async waitUntilDataInitiated() {
      if (!this.dataInitiated) {
        this.loading = true;

        const interval = setInterval(() => {
          if (!this.dataInitiated && !this.error) {
            return;
          }

          clearInterval(interval);
          this.goToNextStep();
          this.loading = false;
        }, 200);

        return;
      }

      this.goToNextStep();
    },

    async checkFormIsValid() {
      await this.$refs.form.validate();
      const isValid = !this.$refs.form.flags.invalid;

      if (!isValid && this.formIsValid) {
        this.setFormIsValid(false);
      }

      if (isValid && !this.formIsValid) {
        this.setFormIsValid(true);
      }

      return isValid;
    },

    scrollToFaultyInput() {
      this.$vuetify.goTo(document.querySelector('.input--error'), {
        duration: 500,
        offset: 200,
        easing: 'easeInOutCubic'
      });
    },

    async getPreset() {
      this.loading = true;
      this.goToNextStep();

      await this.fetchPreset();

      this.loading = false;
    },

    async sendConfiguration() {
      this.loading = true;
      this.submitFailed && (this.submitFailed = false);

      const { error } = await this.submitConfiguration();

      if (error) {
        this.submitFailed = true;
      } else {
        await this.$router.push({
          name: RouteName.SUCCESS,
          query: { ...this.$route.query }
        });
      }

      this.loading = false;
    },

    async onRetryAfterError() {
      this.loading = true;
      this.resetError();

      if (this.currentStepConfig.onError) {
        await this.currentStepConfig.onError();
      } else {
        await this.onError();
      }

      this.loading = false;
    },

    async onError() {
      this.currentStep--;
      await this.onNext();
    },

    resetError() {
      this.setError(false);
    }
  }
};
</script>

<style scoped lang="scss">
.stepper__progressbar {
  width: 100%;
  position: fixed;
}

.step__container {
  margin: 2rem auto 3rem auto;
  padding: 0 1.5rem;
}

.step__spinner {
  margin: 5rem auto;
}

.step__error {
  margin-top: 5rem;
}

.background {
  background-image: url('~@/assets/background-light.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;

  &.background--accent {
    background-image: url($backgroundPath);
  }
}

::v-deep {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .step__headline {
      max-width: 50vw;
    }
  }

  .step__title {
    margin-bottom: 0.5rem;
  }

  .step__area {
    margin-top: 6rem;

    &.dense {
      margin-top: 4rem;
    }
  }
}
</style>
