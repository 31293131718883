<template>
  <div class="filter__area">
    <v-row v-if="preFilterConfig.items.length > 1">
      <v-col cols="12" sm="4" md="3">
        <app-filter
          v-model="selectedPreFilter"
          :items="preFilterConfig.items"
          :label="preFilterConfig.label"
          :multiple="preFilterConfig.multiple"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(filterConfig, index) in additionalFilterConfigs"
        :key="index"
        cols="12"
        sm="4"
        md="3"
      >
        <app-filter
          v-model="selectedFilters[filterConfig.type]"
          :items="filterConfig.items"
          :label="filterConfig.label"
          :multiple="filterConfig.multiple"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MedicalSpeciality from '@/statics/medicalSpeciality';
import { GastroenterologyScopeFilterMap, PulmonologyScopeFilterMap } from '@/statics/scopeFilter';
import { flattenArray } from '@/helper/flattenArray';
import { getFlattenedProceduresList } from '@/helper/procedures';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ScopeFilters',

  props: {
    selectableProducts: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    selectedPreFilter: '',
    selectedFilters: {}
  }),

  computed: {
    ...mapState(['selectedProceduresIds', 'medicalSpeciality']),
    ...mapGetters(['filteredProcedures']),
    preFilterConfig() {
      if (this.medicalSpeciality === MedicalSpeciality.PULMONOLOGY) {
        return PulmonologyScopeFilterMap.preFilter;
      }

      return { ...GastroenterologyScopeFilterMap.preFilter, items: this.getGiPreFilterItems() };
    },
    additionalFilterConfigs() {
      if (!this.selectedPreFilter) {
        return [];
      }

      if (this.isPulmonology) {
        return PulmonologyScopeFilterMap[this.selectedPreFilter];
      }

      return GastroenterologyScopeFilterMap[this.selectedPreFilter];
    },
    filteredProducts() {
      let filteredScopes = [...this.selectableProducts];
      // in Gastroenterology selectable scopes must match at least one of the possible procedure areas
      if (!this.isPulmonology && !this.selectedPreFilter) {
        filteredScopes = filteredScopes.filter((scope) =>
          this.preFilterConfig.items.some((item) => scope.filterProperties.includes(item.value))
        );
      }

      // selectable scopes must match each active filter value
      const activeFilters = [
        ...(this.selectedPreFilter ? [this.selectedPreFilter] : []),
        ...flattenArray(Object.values(this.selectedFilters))
      ];

      filteredScopes = filteredScopes.filter((scope) =>
        activeFilters.every((filter) => {
          if (!filter) {
            return true;
          }
          return scope.filterProperties.includes(filter);
        })
      );

      return filteredScopes;
    },
    isPulmonology() {
      return this.medicalSpeciality === MedicalSpeciality.PULMONOLOGY;
    }
  },

  watch: {
    selectedPreFilter() {
      // reset selected filters if procedure area changed
      this.selectedFilters = {};
    },
    filteredProducts: {
      handler() {
        return this.$emit('input', this.filteredProducts);
      },
      immediate: true
    }
  },

  methods: {
    getGiPreFilterItems() {
      const filteredProcedures = getFlattenedProceduresList(this.filteredProcedures);

      // get possible filter values from property "scopeFilter" of selected procedures
      const possibleFiltersForSelectedProcedures = this.selectedProceduresIds.reduce(
        (filterList, procedureId) => {
          const currentFilter = filteredProcedures.find(
            (procedure) => procedure.id === procedureId
          ).scopeFilter;

          return [...filterList, ...currentFilter];
        },
        []
      );

      // if only one area possible, show their additional filters instead
      if (possibleFiltersForSelectedProcedures.length === 1) {
        this.selectedPreFilter = possibleFiltersForSelectedProcedures[0];
      }

      // area filter items must be supported by at least one selected procedure
      return GastroenterologyScopeFilterMap.preFilter.items.filter((item) =>
        possibleFiltersForSelectedProcedures.includes(item.value)
      );
    }
  }
};
</script>

<style scoped>
.filter__area {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
