<template>
  <v-img v-if="!isHidden" v-bind="imageProps" @error="$emit('error', $event)">
    <slot></slot>
  </v-img>
</template>

<script>
import Article from '@/statics/article';
import ArticleType from '@/statics/articleType';

export default {
  name: 'ProductImage',

  props: {
    alt: {
      type: String,
      default: ''
    },
    article: {
      type: Object,
      default: null
    },
    id: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    }
  },

  computed: {
    imageProps() {
      return {
        ...this.$attrs,
        alt: this.buildAlt(this.article),
        class: this.buildImageClasses(this.article),
        id: this.buildImageId(this.article),
        src: this.buildImageSrc(this.article)
      };
    },
    isHidden() {
      if (!this.$route.query.hidden) {
        return;
      }

      return this.$route.query.hidden.includes(this.article?.articleNumber);
    }
  },

  methods: {
    buildAlt(article) {
      if (this.alt) {
        return this.alt;
      }

      return article.name || article.title || article.productCode;
    },

    buildImageClasses(article) {
      if (!article) {
        return;
      }

      return {
        'trolley__image--article': true,
        'trolley__image--scope': article.type === ArticleType.SCOPE,
        'trolley__image--above':
          (article.type === ArticleType.ACCESSORY && article.layerPosition === 'top') ||
          article.articleNumber === Article.APU_300.articleNumber,
        'trolley__image--below':
          article.type === ArticleType.ACCESSORY && article.layerPosition === 'bottom'
      };
    },

    buildImageId(article) {
      if (this.id) {
        return this.id;
      }

      return `article-${article.articleNumber}`;
    },

    buildImageSrc(article) {
      if (this.src) {
        return this.src;
      }

      if (article.type === ArticleType.ACCESSORY) {
        return `/product-images/accessories/${article.detailImageName}`;
      }

      return `/product-images/${article.detailImageName}`;
    }
  }
};
</script>

<style scoped lang="scss">
.trolley__image--article {
  @include z-index(base); // 1

  &.trolley__image--below {
    @include z-index(zero); // 0
  }

  &.trolley__image--above {
    @include z-index(above); // 2
  }

  &.trolley__image--scope {
    @include z-index(top); // 3
  }
}
</style>
