<template>
  <div
    v-resize="setProgress"
    :style="cssVars"
    :class="{ 'progress-bar__container': true, 'background--accent': currentStep === 1 }"
  >
    <v-progress-linear :value="progress" height="" />
    <div class="stepper__container container">
      <v-row no-gutters justify="space-between">
        <template v-for="stepConfig in stepConfigs">
          <v-col :key="stepConfig.step" cols="auto" class="step__wrapper">
            <app-button
              :disabled="stepConfig.step > currentStep"
              class="step__button caption"
              @click="changeStep(stepConfig.step)"
            >
              {{ stepConfig.step }}
            </app-button>
            <span class="overline d-none d-sm-flex">
              {{ stepConfig.title }}
            </span>
          </v-col>
          <v-col
            v-if="stepConfig.step < stepConfigs.length"
            :key="'separator_' + stepConfig.step"
            cols="auto"
            class="overline step__separator"
          >
            <icon-angle-right />
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
import IconAngleRight from '@/assets/icons/icon-angle-right.svg';
import StepsMixin from '@/mixins/StepsMixin';

export default {
  name: 'StepperProgressBar',

  components: { IconAngleRight },

  mixins: [StepsMixin],

  data: () => ({
    progress: 10
  }),

  computed: {
    stepConfigs() {
      return Object.keys(this.$t('progressBarTitles')).map((translationKey, index) => ({
        step: index + 1,
        title: this.$t(`progressBarTitles.${translationKey}`)
      }));
    },
    cssVars() {
      return { '--progress-bar-margin': this.$vuetify.breakpoint.smAndUp ? '2.5rem' : '1rem' };
    }
  },

  watch: {
    currentStep() {
      this.setProgress();
    }
  },

  methods: {
    changeStep(step) {
      if (step >= this.currentStep) {
        return;
      }
      this.currentStep = step;
    },

    setProgress() {
      if (this.currentStep === this.stepConfigs.length) {
        return (this.progress = 100);
      }

      const targetSeparator = document.querySelectorAll('.step__separator')[this.currentStep - 1];

      if (!targetSeparator) {
        return;
      }

      this.progress =
        ((targetSeparator.getBoundingClientRect().right +
          targetSeparator.getBoundingClientRect().left) /
          2 /
          window.innerWidth) *
        100;
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/colors.scss';

$stepButtonSize: 1.5rem;
$progressBarHeight: 0.2rem;

.v-application {
  .overline {
    color: rgba($text, 0.6);
  }

  .progress-bar__container {
    display: flex;
    justify-content: center;
    position: sticky;
    width: 100%;
    top: 6.75rem;
    margin-bottom: var(--progress-bar-margin);
    @include z-index(belowHeader); // below header, because locale changer menu needs to sit on top

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -2rem;
      width: 100%;
      height: 2rem;
      background-color: var(--v-white-base);
    }

    &:after {
      content: '';
      background: var(--v-white-base);
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      position: absolute;
      bottom: 0;
      top: 0;
      height: calc(#{var(--progress-bar-margin)} + 0.5rem);
      width: 100%;
    }

    &.background--accent:after {
      background-image: url($backgroundPath);
    }
  }

  .v-progress-linear {
    background-color: white;
    height: $progressBarHeight;
    @include z-index(header);
  }

  .stepper__container {
    position: absolute;
    top: calc(#{$progressBarHeight -$stepButtonSize} / 2);
    width: 100%;
    padding: 0 0.75rem;
    @include z-index(header);
    display: flex;
    justify-content: space-between;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0 1.5rem;
    }

    .step__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .step__button.v-btn {
      width: $stepButtonSize;
      height: $stepButtonSize;
      min-width: unset;
      min-height: unset;
      padding: 0;

      &[disabled] {
        background-color: var(--v-accent-light-base) !important;
        color: var(--v-accent-dark-base) !important;
      }
    }

    .step__separator {
      margin-top: 1.65rem;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        opacity: 0;
      }

      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }
}
</style>
