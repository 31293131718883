import Configurator from '@/views/Configurator';
import Layout from '@/components/layout/Layout';
import Localization from '@/helper/localization';
import RouteName from '@/statics/routeName';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import { DEV } from '@/statics/environments';

Vue.use(VueRouter);
Vue.use(VueMeta);

const isDevelopment = process.env.VUE_APP_ENV === DEV;

const routes = [
  {
    path: '/:locale',
    component: Layout,
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale;

      if (!Localization.isLocaleSupported(locale)) {
        // support older configuration links without locale param
        if (to.path === '/link/') {
          return next('/en/link/' + window.location.href.split('/link/')[1]);
        }

        return next(Localization.getUserSupportedLocale());
      }

      return Localization.changeLocale(locale).then(() => next());
    },
    children: [
      {
        path: 'configurator',
        name: RouteName.CONFIGURATOR,
        component: Configurator,
        meta: {
          withProgressBar: true
        },
        alias: ''
      },
      {
        path: 'success',
        name: RouteName.SUCCESS,
        component: () => import('@/views/SuccessScreen.vue' /*webpackChunkName: "SuccessScreen" */),
        meta: {
          withProgressBar: true
        }
      },
      {
        path: 'link/:configurationId',
        name: RouteName.RESTORE_CONFIGURATION,
        component: () =>
          import('@/views/RestoreConfiguration.vue' /* webpackChunkName: "RestoreConfiguration" */)
      },
      ...(isDevelopment
        ? [
            {
              path: 'base',
              name: 'Base Components',
              component: () =>
                import('@/views/BaseComponents.vue' /* webpackChunkName: "BaseComponents"  */)
            }
          ]
        : []),
      {
        path: '*',
        redirect: '/:locale/'
      }
    ]
  },
  {
    path: '*',
    redirect: Localization.getUserSupportedLocale()
  }
];

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from) {
    if (to.name === from.name && String(to.query.step) === String(from.query.step)) {
      return;
    }

    return { x: 0, y: 0 };
  }
});

export default router;
