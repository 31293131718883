import Trolley from '@/statics/trolley';

// list of products which are mandatory requirements for the passed product,
// at least one must be selected to enable the current
export const getMandatoryProducts = (
  product,
  mandatoryProductsData,
  selectedProductsArticleNumbers
) => {
  const mandatoryProductsWithoutTrolleys = [...(product.mandatoryProducts ?? [])].filter(
    (mandatoryProductArticleNumber) =>
      !Object.values(Trolley).includes(mandatoryProductArticleNumber)
  );

  const oneMandatoryProductSelected = mandatoryProductsWithoutTrolleys.some((articleNumber) =>
    selectedProductsArticleNumbers.includes(articleNumber)
  );

  if (mandatoryProductsWithoutTrolleys.length === 0 || oneMandatoryProductSelected) {
    return [];
  }

  return mandatoryProductsWithoutTrolleys
    .map((articleNumber) => {
      const mandatoryProduct = mandatoryProductsData.find(
        (productData) => productData.articleNumber === articleNumber
      );

      if (!mandatoryProduct) {
        return;
      }

      return `${mandatoryProduct.name} (${mandatoryProduct.productCode})`;
    })
    .filter((item) => !!item);
};

// list of products which require the passed mandatory one,
// all have to be unselected to enable the current
export const getProductsWithSpecifiedMandatory = (
  product,
  selectableProducts,
  selectedProductsArticleNumbers
) => {
  if (!selectedProductsArticleNumbers.includes(product.articleNumber)) {
    return [];
  }

  return selectableProducts
    .filter((selectableProduct) => {
      return (
        selectedProductsArticleNumbers.includes(selectableProduct.articleNumber) &&
        [...(selectableProduct.mandatoryProducts ?? [])].includes(product.articleNumber)
      );
    })
    .map((product) => `${product.name} (${product.productCode})`);
};
