import i18n from '@/plugins/i18n';

export const withSorting = (items, nestedProperty) => {
  const selectedLocale = i18n.locale;

  if (!nestedProperty) {
    return items.sort((a, b) => a.localeCompare(b, selectedLocale));
  }

  return items.sort((a, b) => a[nestedProperty].localeCompare(b[nestedProperty], selectedLocale));
};
