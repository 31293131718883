var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',_vm._g({attrs:{"loading":_vm.loading,"value":_vm.value,"query-param":_vm.queryParam},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('changeArticle.subline')))]),_c('h2',[_vm._v(_vm._s(_vm.$t('changeArticle.headline')))])]},proxy:true}])},_vm.$listeners),[(_vm.selectableProducts.length === 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('changeArticle.noArticlesAvailable'))+" ")]):[_c(_vm.isMultiSelect ? 'v-row' : 'app-radio',_vm._b({key:_vm.pageStartIndex,tag:"component",staticClass:"selectable-articles__list"},'component',
        !_vm.isMultiSelect
          ? {
              value: _vm.newArticleNumbers[0],
              row: true,
              dense: true
            }
          : {}
      ,false),[_c('page-switch-column',{attrs:{"hide":_vm.pages === 1,"icon":"$iconArrowLeft","disabled":_vm.pageStartIndex === 0},on:{"click":function($event){_vm.pageStartIndex = _vm.pageStartIndex - _vm.articlesPerPage}}},[(_vm.pageStartIndex > 0)?[_vm._v(" "+_vm._s(_vm.mainSelectableProducts[_vm.pageStartIndex - 1].productCode)+" ")]:_vm._e()],2),_vm._l((_vm.mainSelectableProducts.slice(
          _vm.pageStartIndex,
          _vm.pageStartIndex + _vm.articlesPerPage
        )),function(product){return _c('v-col',{key:product.articleNumber,staticClass:"selectable-articles__item"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(product.name || product.title)+" ")]),_vm._v(" "+_vm._s(product.productCode || ' ')+" "),_c('v-card',{class:{
            'selectable-articles__card': true,
            'selectable-articles__card--shown': _vm.showArticleNumber === product.articleNumber,
            'selectable-articles__card--selected': _vm.newArticleNumbers.includes(
              product.articleNumber
            )
          },attrs:{"outlined":""},on:{"click":function($event){_vm.showArticleNumber = product.articleNumber}}},[_c('div',{staticClass:"selectable-articles__image__wrapper"},[_c('product-image',{attrs:{"article":product,"height":"8rem","max-width":"90%","contain":""}})],1),_c('icon-check',{staticClass:"selectable-articles__preview selectable-articles__preview--selected"}),(_vm.showArticleNumber !== product.articleNumber)?_c('div',{staticClass:"selectable-articles__preview selectable-articles__preview--info"},[_c('app-button',{attrs:{"text":"","link":"","label":_vm.$t('changeArticle.showDetails')}})],1):_vm._e()],1),_c('div',{staticClass:"selectable-articles__actions"},[(_vm.isMultiSelect)?_c('requirements-tooltip',_vm._b({key:product.articleNumber,scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var disabled = ref.disabled;
return [_c('app-checkbox',_vm._b({attrs:{"disabled":disabled},on:{"input":function($event){_vm.showArticleNumber = product.articleNumber}},model:{value:(_vm.newArticleNumbers),callback:function ($$v) {_vm.newArticleNumbers=$$v},expression:"newArticleNumbers"}},'app-checkbox',_vm.getCheckboxProps(product),false))]}}],null,true)},'requirements-tooltip',_vm.getTooltipProps(product),false)):_c('v-radio',{attrs:{"color":"primary","value":product.articleNumber,"label":_vm.newArticleNumbers[0] === product.articleNumber
                ? _vm.$t('actions.selected')
                : _vm.$t('actions.select')},on:{"click":function($event){return _vm.onSelectArticle(product.articleNumber)}}})],1)],1)}),_c('v-spacer'),_c('page-switch-column',{attrs:{"hide":_vm.pages === 1,"icon":"$iconArrowRight","disabled":_vm.pageStartIndex + _vm.articlesPerPage >= _vm.mainSelectableProducts.length},on:{"click":function($event){_vm.pageStartIndex = _vm.pageStartIndex + _vm.articlesPerPage}}},[(_vm.pageStartIndex + _vm.articlesPerPage < _vm.mainSelectableProducts.length)?[_vm._v(" "+_vm._s(_vm.mainSelectableProducts[_vm.pageStartIndex + _vm.articlesPerPage].productCode)+" ")]:_vm._e()],2)],2),(_vm.isMultiSelect && _vm.optionalSelectableProducts.length > 0)?_c('v-row',{staticClass:"selectable-articles__list selectable-articles__list--optionals"},[_c('v-col',{staticClass:"change-articles__column--main selectable-articles__item selectable-articles__item--none"},[_c('div',{staticClass:"selectable-articles__actions"},_vm._l((_vm.optionalSelectableProducts),function(product){return _c('requirements-tooltip',_vm._b({key:product.articleNumber,scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var disabled = ref.disabled;
return [_c('app-checkbox',_vm._b({attrs:{"disabled":disabled},on:{"input":function($event){_vm.showArticleNumber = product.articleNumber}},model:{value:(_vm.newArticleNumbers),callback:function ($$v) {_vm.newArticleNumbers=$$v},expression:"newArticleNumbers"}},'app-checkbox',_vm.getCheckboxProps(product),false))]}}],null,true)},'requirements-tooltip',_vm.getTooltipProps(product),false))}),1)])],1):_vm._e(),(!_vm.isMultiSelect && _vm.currentZone.isClearable)?_c('app-radio',{staticClass:"selectable-articles__list",attrs:{"value":_vm.newArticleNumbers[0],"row":"","dense":""}},[_c('v-col',{staticClass:"change-articles__column--main selectable-articles__item selectable-articles__item--none"},[_c('div',{staticClass:"selectable-articles__actions",on:{"click":function($event){return _vm.onSelectArticle(_vm.valueNone)}}},[_c('v-radio',{attrs:{"color":"primary","value":_vm.valueNone,"label":_vm.newArticleNumbers[0] === _vm.valueNone
                ? _vm.$t('actions.selectedNothing')
                : _vm.$t('actions.selectNothing')}})],1)])],1):_vm._e()],_c('v-row',{staticClass:"change-articles__row"},[_c('v-col',{staticClass:"change-articles__column--main"},[(_vm.selectedArticle)?_c('article-info',{attrs:{"article":_vm.selectedArticle,"show-change-hint":_vm.isDowngradeFromCV1500}}):_vm._e()],1)],1),(_vm.pages > 1)?_c('div',{staticClass:"bottom-pagination"},_vm._l((_vm.pages),function(page){return _c('app-button',{key:page,attrs:{"icon":"","text":"","color":_vm.activePage === page ? 'primary' : 'grey-inactive'},on:{"click":function($event){_vm.pageStartIndex = (page - 1) * _vm.articlesPerPage}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-circle")])],1)}),1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }