<template>
  <v-row
    v-if="trolleys.length > 1"
    align="stretch"
    justify="center"
    no-gutters
    class="trolley__menu"
  >
    <v-col
      v-for="(trolley, index) in trolleys"
      :key="index"
      :class="{ active: trolley === active }"
    >
      <app-button link block @click="scrollTo(`#trolley-${trolley}`)">
        {{ $t(`optimize.trolleys.${trolley}`) }}
      </app-button>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TrolleyMenu',

  props: {
    active: {
      type: String,
      required: true
    },
    trolleys: {
      type: Array,
      required: true
    }
  },

  methods: {
    scrollTo(target) {
      this.$vuetify.goTo(target, {
        duration: 500,
        offset: 160,
        easing: 'easeInOutCubic'
      });
    }
  }
};
</script>

<style scoped lang="scss">
.trolley__menu {
  flex-wrap: nowrap;

  > .col {
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-color: var(--v-primary-base);
    border-style: solid;
    border-width: $borderWidth $borderWidth $borderWidth 0;

    &:first-of-type {
      border-left-width: $borderWidth;
    }

    &.active {
      color: var(--v-white-base);
      background-color: var(--v-primary-base);

      ::v-deep {
        .v-btn.button--link {
          color: var(--v-white-base) !important;
        }
      }
    }

    ::v-deep {
      .v-btn.button--link {
        display: block;
        flex: 0;
        padding: 0.25rem;
        white-space: normal;
      }
    }
  }
}
</style>
