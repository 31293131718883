<template>
  <v-btn
    depressed
    :outlined="outlined"
    :color="color"
    v-bind="$attrs"
    :class="{ 'body-1': true, 'button--icon-only': hasIconWithoutLabel, 'button--link': link }"
    :text="text || link"
    :icon="hasIconWithoutLabel && text"
    v-on="$listeners"
  >
    <slot>
      <v-icon v-if="icon">{{ icon }}</v-icon>
      <span v-if="label" class="button__label">{{ label }}</span>
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: 'AppButton',

  props: {
    color: { type: String, default: 'primary' },
    icon: { type: [String, Boolean], default: undefined },
    label: { type: String, default: '' },
    link: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    text: { type: Boolean, default: false }
  },

  computed: {
    hasIconWithoutLabel() {
      return this.icon !== undefined && !this.label;
    }
  }
};
</script>

<style scoped lang="scss">
$iconButtonSize: 2.5rem;

.v-application.theme--light .v-btn {
  padding: 0.5rem 1.5rem;
  height: auto;
  min-height: 2.5rem;
  text-transform: none;
  border-radius: 0;
  text-indent: 0;

  &.button--link {
    padding: 0.5rem 0.25rem;
    margin: 0 -0.25rem;

    &:hover::before,
    &:focus::before {
      opacity: 0;
    }

    .button__label {
      text-decoration: underline;
    }
  }

  &.button--icon-only {
    width: $iconButtonSize;
    height: $iconButtonSize;
    padding: 0;
    min-height: unset;

    &.v-size--small {
      min-width: unset;
    }
  }

  &:not(.button--icon-only):not(.v-btn--loading) {
    ::v-deep {
      i,
      svg {
        margin-right: 0.625rem;
      }
    }
  }

  ::v-deep {
    .v-btn__content {
      margin-top: $fontVerticalOffsetBody2;
    }

    svg,
    i {
      margin-top: -$fontVerticalOffsetBody2;
    }
  }
}
</style>
