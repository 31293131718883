// unique types for each filter select
const ScopeFilterTypes = {
  GI_PROCEDURE_AREAS: 'gi-procedure-areas',
  GI_SERIES: 'gi-series',
  UPPER_GI_FEATURES: 'upper-gi-features',
  LOWER_GI_FEATURES: 'lower-gi-features',
  LOWER_GI_LENGTH: 'lower-gi-length',
  RES_SERIES: 'res-series',
  RES_SCOPE_TYPES: 'res-types'
};

const mapItems = (items, translationKey) =>
  items.map((item) => ({
    label: !translationKey ? item : `scopeFilter.${translationKey}.items.${item}`,
    value: item
  }));

// Select Configs
// Gastroenterology

// possible scope filter values in procedures
export const GIProcedureArea = {
  UPPER_GI: 'Upper GI',
  LOWER_GI: 'Lower GI',
  ERCP: 'ERCP',
  ENTEROSCOPY: 'Enteroscopy',
  ULTRASOUND: 'Ultrasound'
};
const GI_PROCEDURE_AREAS = {
  type: ScopeFilterTypes.GI_PROCEDURE_AREAS,
  label: 'scopeFilter.area.label',
  items: mapItems(Object.values(GIProcedureArea), 'area'),
  multiple: false
};

export const GIScopeSeries = {
  1500: '1500',
  1100_1200: '1100/1200',
  185_190_290: '185/190/290',
  170: '170'
};
const GI_SERIES = {
  type: ScopeFilterTypes.GI_SERIES,
  label: 'scopeFilter.series.label',
  // items explicitly listed to keep order
  items: mapItems([
    GIScopeSeries[1500],
    GIScopeSeries[1100_1200],
    GIScopeSeries[185_190_290],
    GIScopeSeries[170]
  ]),
  multiple: false
};

export const Feature = {
  WATER_JET: 'Water Jet',
  HQ: 'HQ',
  TRANSNASAL: 'Transnasal',
  PCF: 'PCF'
};
const GI_FEATURES = {
  label: 'scopeFilter.features.label',
  items: mapItems([Feature.WATER_JET, Feature.HQ], 'features'),
  multiple: true
};
const UPPER_GI_FEATURES = {
  ...GI_FEATURES,
  type: ScopeFilterTypes.UPPER_GI_FEATURES,
  items: [...GI_FEATURES.items, ...mapItems([Feature.TRANSNASAL], 'features')],
  multiple: true
};
const LOWER_GI_FEATURES = {
  ...GI_FEATURES,
  type: ScopeFilterTypes.LOWER_GI_FEATURES,
  items: [...GI_FEATURES.items, ...mapItems([Feature.PCF], 'features')],
  multiple: true
};

export const LowerGILength = {
  INTERMEDIATE: 'Intermediate',
  LONG: 'Long'
};
const LOWER_GI_LENGTH = {
  type: ScopeFilterTypes.LOWER_GI_LENGTH,
  label: 'scopeFilter.length.label',
  items: mapItems(Object.values(LowerGILength), 'length'),
  multiple: false
};

export const GastroenterologyScopeFilterMap = {
  preFilter: GI_PROCEDURE_AREAS,
  [GIProcedureArea.UPPER_GI]: [GI_SERIES, UPPER_GI_FEATURES],
  [GIProcedureArea.LOWER_GI]: [GI_SERIES, LOWER_GI_LENGTH, LOWER_GI_FEATURES]
};

// Pulmonology
export const RespiratoryType = {
  BRONCHOSCOPE: 'Bronchoscope',
  MINIPROBE: 'Miniprobe',
  PLEURASCOPE: 'Pleurascope',
  ULTRASOUND: 'Ultrasound'
};
const RESPIRATORY_TYPES = {
  type: ScopeFilterTypes.RES_SCOPE_TYPES,
  label: 'scopeFilter.respiratoryType.label',
  items: mapItems(Object.values(RespiratoryType), 'respiratoryType'),
  multiple: false
};

export const RespiratorySeries = {
  1100_1200: '1100/1200',
  190_290: '190/290',
  170: '170'
};
const RESPIRATORY_SERIES = {
  type: ScopeFilterTypes.RES_SERIES,
  label: 'scopeFilter.series.label',
  // items explicitly listed to keep order
  items: mapItems([
    RespiratorySeries[1100_1200],
    RespiratorySeries[190_290],
    RespiratorySeries[170]
  ]),
  multiple: false
};

export const PulmonologyScopeFilterMap = {
  preFilter: RESPIRATORY_TYPES,
  [RespiratoryType.BRONCHOSCOPE]: [RESPIRATORY_SERIES]
};
