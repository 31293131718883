import Vue from 'vue';

import AppButton from '@/components/base/AppButton';
import AppCheckbox from '@/components/base/AppCheckbox';
import AppDialog from '@/components/base/AppDialog';
import AppFilter from '@/components/base/AppFilter';
import AppRadio from '@/components/base/AppRadio';
import AppSpinner from '@/components/base/AppSpinner';
import AppTextField from '@/components/base/AppTextField';

Vue.component('app-button', AppButton);
Vue.component('app-checkbox', AppCheckbox);
Vue.component('app-dialog', AppDialog);
Vue.component('app-filter', AppFilter);
Vue.component('app-radio', AppRadio);
Vue.component('app-spinner', AppSpinner);
Vue.component('app-text-field', AppTextField);
