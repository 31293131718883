<template>
  <div class="trolley__info">
    <b class="trolley__description">
      {{ $t('optimize.esgDescription') }}
    </b>

    <v-row justify="center" justify-sm="space-between">
      <trolley-with-product-images :trolley="trolley" />

      <v-col cols="12" sm="7">
        <ul class="trolley__articles">
          <template v-for="(article, index) in articlesFlatList">
            <li :key="article.articleNumber" class="article__item">
              {{ article.title || article.productCode }}
              <toggle-hide-button :article-number="article.articleNumber" />
            </li>

            <hr v-if="index + 1 < articlesFlatList.length" :key="'divider_' + index" />
          </template>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ToggleHideButton from '@/components/steps/3/ToggleHideButton';
import TrolleyWithProductImages from '@/components/steps/3/TrolleyWithProductImages';

export default {
  name: 'EsgTrolley',

  components: { TrolleyWithProductImages, ToggleHideButton },

  props: {
    trolley: {
      type: Object,
      required: true
    }
  },

  computed: {
    articlesFlatList() {
      return this.trolley.articleZones.reduce(
        (articleList, zone) => [...articleList, ...zone.articles],
        []
      );
    }
  }
};
</script>

<style scoped lang="scss">
.trolley__info {
  color: var(--v-text-base);

  .trolley__description {
    margin-top: 1rem;
    display: inline-block;
  }

  .trolley__articles {
    margin-top: 3rem;
    padding: 0;
    list-style-type: none;
  }

  .article__item {
    margin: 0.5rem 0;
  }
}
</style>
