<template>
  <v-col cols="8" sm="5" md="4" lg="3" :class="containerClasses">
    <product-image
      :id="`article-${trolley.type}`"
      :alt="$t(`optimize.trolleys.${trolley.type}`)"
      :src="`/product-images/${trolley.type}.png`"
    >
      <product-image
        v-for="article in availableImages"
        :key="article.articleNumber"
        :article="article"
        :src="buildImageSrc(article)"
        @error="addMissingImage($event, article)"
      />

      <template v-if="isDevelopment">
        <product-image
          v-for="article in articlesWithoutImages"
          :key="`fallback-${article.articleNumber}`"
          :src="`/product-images/fallback/${trolley.type}_${article.zone.index}_${article.area}.png`"
          :article="article"
        />
      </template>
    </product-image>
  </v-col>
</template>

<script>
import ArticleType from '@/statics/articleType';
import EnvMixin from '@/mixins/EnvMixin';
import ProductImage from '@/components/ProductImage';
import { Trolley } from '@/statics/trolley';
import { mapState } from 'vuex';

export default {
  name: 'TrolleyWithProductImages',

  components: {
    ProductImage
  },

  mixins: [EnvMixin],

  props: {
    accessories: {
      type: Array,
      default: () => []
    },
    trolley: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      articlesWithoutImages: []
    };
  },

  computed: {
    ...mapState(['overwriteDummyScope']),
    availableImages() {
      return [
        ...this.trolley.articleZones.reduce(
          (flatList, zone) => [...flatList, ...zone.articles],
          []
        ),
        ...[this.trolley.scopeZones[0]?.articles[0] || {}],
        ...(this.trolley.type === Trolley.MAIN ? this.accessories : [])
      ].filter(
        (articleInZone) =>
          !!articleInZone.articleNumber &&
          !this.articlesWithoutImages.some(
            (article) => article.articleNumber === articleInZone.articleNumber
          )
      );
    },

    containerClasses() {
      return {
        'trolley__image-container': true,
        'trolley__image-container--esg': this.trolley.type === Trolley.ESG
      };
    }
  },

  methods: {
    addMissingImage(imgSrc, article) {
      this.articlesWithoutImages.push(article);
    },

    buildImageSrc(article) {
      if (article.type === ArticleType.ACCESSORY) {
        return `/product-images/accessories/${article.trolleyImageName}`;
      }

      if (article.type === ArticleType.SCOPE && this.overwriteDummyScope) {
        return `/product-images/${this.overwriteDummyScope.trolleyImageName}`;
      }

      return `/product-images/${article.trolleyImageName}`;
    }
  }
};
</script>

<style scoped lang="scss">
.trolley__image-container {
  position: relative;

  &.trolley__image-container--esg {
    margin-top: -6rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-top: -10rem;
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      margin-top: -8rem;
    }
  }
}

.trolley__image--article {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
