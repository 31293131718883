<template>
  <div>
    <h2>{{ $t('accessories.headline') }}</h2>

    <div class="accessories__list">
      <AccessoryListItem
        v-for="(item, index) in accessories"
        :key="item.productCode"
        :accessory="item"
        :is-last-one="index + 1 >= accessories.length"
        :is-selected="selectedAccessoriesProductCodes.includes(item.productCode)"
        @input="changeSelectedAccessories(item.productCode)"
      />
    </div>
  </div>
</template>

<script>
import AccessoryListItem from '@/components/steps/4/AccessoryListItem';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'StepFourAccessories',

  components: { AccessoryListItem },

  computed: {
    ...mapState(['accessories', 'selectedAccessoriesProductCodes'])
  },

  methods: {
    ...mapMutations(['setSelectedAccessoriesProductCodes', 'setAccessories']),

    changeSelectedAccessories(productCode) {
      const updateIndex = this.selectedAccessoriesProductCodes.indexOf(productCode);
      const updatedItems = [...this.selectedAccessoriesProductCodes];
      updateIndex === -1 ? updatedItems.push(productCode) : updatedItems.splice(updateIndex, 1);

      this.setSelectedAccessoriesProductCodes(updatedItems);
    }
  }
};
</script>

<style scoped lang="scss">
.accessories__list {
  margin-top: 1rem;
}
</style>
