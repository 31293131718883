<template>
  <v-toolbar class="app-header" color="white" flat>
    <div class="app-header__content container">
      <router-link :to="currentLocale">
        <img src="https://cdn.aws.olympus.eu/static/img/logo/olympus-logo.svg" alt="Olympus" />
      </router-link>

      <locale-changer v-if="currentStep < 3" class="locale-changer" />
    </div>
  </v-toolbar>
</template>

<script>
import EnvMixin from '@/mixins/EnvMixin';
import LocaleChanger from '@/components/LocaleChanger';
import Localization from '@/helper/localization';
import StepsMixin from '@/mixins/StepsMixin';

export default {
  name: 'AppHeader',

  components: { LocaleChanger },

  mixins: [EnvMixin, StepsMixin],

  computed: {
    currentLocale() {
      return Localization.currentLocale;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .button__icon {
  width: 2rem;
  height: 2rem;

  &.small {
    width: 0.8rem;
    height: 0.8rem;
  }
}

.v-application .app-header {
  height: 5.75rem !important;
  padding-top: 1rem;
  @include z-index(header);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .app-header__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 1.5rem;
  }

  ::v-deep {
    .v-toolbar__content {
      padding: 0;
    }

    .locale-changer {
      margin-right: -1.5rem;
    }
  }
}
</style>
