import Vue from 'vue';

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';

import { email, numeric, alpha_spaces, required } from 'vee-validate/dist/rules';

const rules = { email, numeric, alpha_spaces, required };
Object.keys(rules).forEach((key) => extend(key, rules[key]));

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
