import ProcedureType from '@/statics/procedureType';

export const mapProcedures = (procedures) => ({
  [ProcedureType.DIAGNOSTIC]: procedures.diagnostic,
  [ProcedureType.THERAPEUTIC]: procedures.therapeutic
});

export const getFlattenedProceduresList = (procedures) =>
  Object.values(procedures).reduce((proceduresList, proceduresOfSameType) => {
    return [...proceduresList, ...proceduresOfSameType];
  }, []);
