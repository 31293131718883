import RouteName from '@/statics/routeName';

export default {
  computed: {
    currentStep: {
      get() {
        return Number(this.$route.query.step) || 1;
      },
      set(value) {
        this.$router.push({
          name: RouteName.CONFIGURATOR,
          query: { ...this.$route.query, step: value }
        });
      }
    }
  }
};
