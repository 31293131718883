export const GlobalDialogQuery = {
  CONTACT: 'contact',
  SAVE_CONFIGURATION: 'save-configuration'
};

export const GLOBAL_DIALOG_QUERIES = Object.values(GlobalDialogQuery);

export const ConfiguratorActionQuery = {
  CHANGE_ARTICLE: 'change-article',
  CHANGE_ESG: 'change-HF-generator',
  CHANGE_SCOPE: 'change-scope',
  SHOW_SCOPE_GUIDE: 'show-scopeGuide'
};

export const CONFIGURATOR_ACTION_QUERIES = Object.values(ConfiguratorActionQuery);
