<template>
  <ValidationProvider v-slot="{ errors }" :rules="rules" :name="label" :mode="validationMode">
    <v-checkbox
      v-model="checked"
      :class="{ 'input--error': errors.length > 0 }"
      :error-messages="errors"
      :label="label"
      color="primary"
      hide-details
      on-icon="mdi-checkbox-intermediate"
      v-bind="fieldAttrs"
    >
      <template #label>
        <slot></slot>
      </template>
    </v-checkbox>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'AppCheckbox',

  props: {
    itemValue: { type: String, default: undefined },
    label: { type: String, default: '' },
    validationMode: { type: String, default: 'eager' },
    rules: { type: Object, default: () => ({}) },
    value: { type: [Boolean, Array], required: true }
  },

  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    fieldAttrs() {
      return { ...this.$attrs, ...(this.itemValue !== undefined ? { value: this.itemValue } : {}) };
    }
  }
};
</script>

<style scoped lang="scss">
.v-input--checkbox {
  @include body-2-font-style;
  color: var(--v-primary-base);
  margin-top: 0;
  padding: 0;
  display: inline-flex;

  ::v-deep {
    .v-input__slot {
      align-items: start;
    }

    .v-input--selection-controls__input {
      margin-top: -$fontVerticalOffsetBody2;
    }

    .theme--light.v-label {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }

    .v-icon {
      color: inherit;
      font-size: calc(100% + 0.25rem);
    }
  }

  &.v-input--is-disabled {
    color: var(--v-grey-disabled-base);

    ::v-deep .v-icon {
      color: var(--v-grey-disabled-base) !important;
    }
  }
}
</style>
