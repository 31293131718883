<template>
  <v-tooltip bottom color="white" content-class="caption text--text elevation-6 tooltip">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <slot></slot>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AppTooltip'
};
</script>

<style scoped lang="scss">
.tooltip {
  opacity: 1 !important;
  padding: 1rem 1.5rem;
}
</style>
