<template>
  <div>
    <template v-for="(trolley, trolleyIndex) in mappedPreset">
      <div :key="trolley.type" class="trolley__container">
        <v-row align="end" justify="space-between">
          <v-col cols="12" sm="5">
            <div :id="`trolley-${trolley.type}`" class="step__title subtitle-1">
              {{ $t('step') }} 3 /
              {{ getTrolleyCount(trolleyIndex) }}
              {{ $t('optimize.title') }}
            </div>
            <h3 class="step__headline">
              <template v-if="getTrolleyCount(trolleyIndex)">
                {{ getTrolleyCount(trolleyIndex) }}.
              </template>
              {{ $t(`optimize.trolleyTitles.${trolley.type}`) }}
            </h3>
          </v-col>
          <v-col cols="12" sm="7">
            <trolley-menu
              :active="trolley.type"
              :trolleys="mappedPreset.map((trolleyConfig) => trolleyConfig.type)"
              :container="$refs.viewContainer"
            />
          </v-col>
        </v-row>
        <v-row class="trolley__content">
          <v-col cols="12">
            <component :is="getTrolleyTemplate(trolley.type)" :trolley="trolley" />
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import EnvMixin from '@/mixins/EnvMixin';
import EsgTrolley from '@/components/steps/3/TrolleyTemplates/EsgTrolley';
import GhostTrolley from '@/components/steps/3/TrolleyTemplates/GhostTrolley';
import MainTrolley from '@/components/steps/3/TrolleyTemplates/MainTrolley';
import ProductImage from '@/components/ProductImage';
import TrolleyMenu from '@/components/steps/3/TrolleyMenu';
import { Trolley } from '@/statics/trolley';
import { mapGetters } from 'vuex';

export default {
  name: 'StepThreeOptimize',

  components: {
    EsgTrolley,
    MainTrolley,
    ProductImage,
    TrolleyMenu
  },

  mixins: [EnvMixin],

  computed: {
    ...mapGetters(['mappedPreset'])
  },

  methods: {
    getTrolleyCount(index) {
      if (this.mappedPreset.length === 1) {
        return '';
      }
      return index + 1;
    },

    getTrolleyTemplate(trolleyType) {
      switch (trolleyType) {
        case Trolley.MAIN:
          return MainTrolley;
        case Trolley.GHOST:
          return GhostTrolley;
        case Trolley.ESG:
          return EsgTrolley;
        default:
          return null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.trolley__container {
  &:not(:first-of-type) {
    margin-top: 5rem;
  }

  &:first-of-type .step__title {
    margin-top: 0;
  }
}

.trolley__content {
  margin-top: 3rem;
}
</style>
