<template>
  <app-button
    v-if="isDevelopment && articleNumber"
    :icon="isHidden(articleNumber) ? 'mdi-eye-off' : 'mdi-eye'"
    class="hide-button"
    small
    text
    color="grey"
    @click.stop="toggleHide(articleNumber)"
  />
</template>

<script>
import EnvMixin from '@/mixins/EnvMixin';

export default {
  name: 'ToggleHideButton',

  mixins: [EnvMixin],

  props: {
    articleNumber: {
      type: String,
      default: ''
    }
  },

  methods: {
    isHidden(articleNumber) {
      return (this.$route.query.hidden ?? []).includes(articleNumber);
    },

    toggleHide(articleNumber) {
      return this.$router.replace({
        query: {
          ...this.$route.query,
          hidden: this.isHidden(articleNumber)
            ? this.$route.query.hidden.filter(
                (hiddenArticleNumber) => hiddenArticleNumber !== articleNumber
              )
            : [...(this.$route.query?.hidden ?? []), articleNumber]
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.v-application.theme--light .hide-button.v-btn.button--icon-only {
  height: auto;
  width: auto;
  margin: -$fontVerticalOffsetBody1 0.25rem 0 0.25rem;

  ::v-deep i {
    margin-right: 0;
  }
}
</style>
