export const ProcedureType = {
  DIAGNOSTIC: 'Diagnostic',
  THERAPEUTIC: 'Therapeutic'
};

// possible selections in step one
export const ProcedureTypeSelectOptions = {
  ADVANCED: 'diagnosticAndTherapeutic',
  GENERAL: 'diagnostic'
};

export const PROCEDURE_TYPE_SELECT_OPTIONS = Object.values(ProcedureTypeSelectOptions);

// possible selections mapped to related procedure types
export const SelectedProcedureTypesMap = {
  [ProcedureTypeSelectOptions.ADVANCED]: [ProcedureType.DIAGNOSTIC, ProcedureType.THERAPEUTIC],
  [ProcedureTypeSelectOptions.GENERAL]: [ProcedureType.DIAGNOSTIC]
};

export default ProcedureType;
