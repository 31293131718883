import Trolley from '@/statics/trolley';

export const Article = {
  CV_1500: {
    articleNumber: 'N6011250'
  },
  SCOPE_GUIDE: {
    articleNumber: 'N3827460A',
    area: 'default',
    zone: {
      index: 'S0',
      trolley: Trolley.SCOPE_GUIDE
    },
    requiredProducts: ['N3827460']
  },
  ESG_150: {
    articleNumber: 'WA90150W'
  },
  ESG_300: {
    articleNumber: 'WA90003W'
  },
  APU_300: {
    articleNumber: 'WA90004W'
  },
  OFP_2: {
    articleNumber: 'K10001143A'
  }
};

export default Article;
