<template>
  <app-dialog
    :loading="loading"
    :query-param="queryParam"
    :value="value"
    @submit="$emit('input', false)"
    v-on="$listeners"
  >
    <template v-slot:title>
      <span class="subtitle-1 font-weight-bold">{{ $t('changeArticle.scopeGuideSubline') }}</span>
      <h2>{{ $t('optimize.scopeGuide') }}</h2>
    </template>

    <h3 class="scope-guide__heading">{{ $t('changeArticle.scopeGuideIntro') }}</h3>
    <v-row>
      <v-col cols="12" sm="5" md="4">
        <v-img src="@/assets/scopeGuide.png" alt="ScopeGuide" />
      </v-col>
      <v-col cols="12" sm="7" md="8">
        <b>{{ $t('changeArticle.scopeGuideSummary') }}</b>
        <p class="scope-guide__description" v-html="$t('changeArticle.scopeGuideDescription')"></p>
      </v-col>
    </v-row>

    <h3 class="scope-guide__heading">{{ $t('changeArticle.scopeGuideIncludedItems') }}</h3>
    <v-row v-if="articles">
      <v-col v-for="article in articles" :key="article.articleNumber" cols="12" sm="5" md="4">
        <b>{{ article.name }}</b>
        <span v-if="isScopeGuideStand(article.articleNumber)">*</span>
        <product-image :article="article" contain class="scope-guide-item__image" />
      </v-col>
    </v-row>
    <span class="caption">*{{ $t('changeArticle.notVisualized') }}</span>
  </app-dialog>
</template>

<script>
import Article from '@/statics/article';
import ProductImage from '@/components/ProductImage';
import { ConfiguratorActionQuery } from '@/statics/dialogQueries';
import { mapActions } from 'vuex';

export default {
  name: 'ScopeGuideDialog',

  components: { ProductImage },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: false,
    articles: [],
    queryParam: ConfiguratorActionQuery.SHOW_SCOPE_GUIDE
  }),

  watch: {
    async value() {
      if (!this.value) {
        return;
      }

      this.loading = true;
      this.articles = await this.fetchArticlesInfo([
        Article.SCOPE_GUIDE.articleNumber,
        ...Article.SCOPE_GUIDE.requiredProducts
      ]);

      this.loading = false;
    }
  },

  methods: {
    ...mapActions(['fetchArticlesInfo']),

    isScopeGuideStand(articleNumber) {
      return articleNumber === Article.SCOPE_GUIDE.articleNumber;
    }
  }
};
</script>

<style scoped lang="scss">
.scope-guide__description {
  margin-top: 1rem;
}

.scope-guide__heading {
  margin: 1rem 0;
  color: var(--v-primary-base);

  &:not(:first-of-type) {
    margin-top: 4rem;
  }
}

.scope-guide-item__image {
  max-width: 80%;
  margin: 2rem 0;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 1rem;
  }
}
</style>
