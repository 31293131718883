<template>
  <v-col
    :class="{
      'change-articles__column--side page-switch__column': true,
      'page-switch__column--hidden': hide
    }"
  >
    <div class="page-switch__wrapper">
      <app-button :disabled="disabled" :icon="icon" small outlined @click="$emit('click')" />
      <div class="pagination__next-product-code caption">
        <slot></slot>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'PageSwitchColumn',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.page-switch__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--v-primary-base);

  &.page-switch__column--hidden {
    opacity: 0;
  }

  .page-switch__wrapper {
    position: relative;
    bottom: 8rem;
  }

  .pagination__next-product-code {
    position: absolute;
    top: calc(100% + 1rem);
    text-align: center;
    word-break: break-word;
    hyphens: auto;
  }
}
</style>
