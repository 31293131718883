import RouteName from '@/statics/routeName';
import { CONFIGURATOR_ACTION_QUERIES, GLOBAL_DIALOG_QUERIES } from '@/statics/dialogQueries';

const Tracking = {
  initiateTrackingObject(route) {
    const env = process.env.VUE_APP_ENV.toUpperCase();
    const device = Tracking.getDevice();

    let appendToBody = false;
    let trackingScript = document.getElementById('trackingObject');

    if (!trackingScript) {
      appendToBody = true;
      trackingScript = document.createElement('script');
      trackingScript.id = 'trackingObject';
    }

    trackingScript.innerHTML = `
        var OLY = OLY || {};
          OLY.trackingObject = {
             general:{
                country: "EU",
                language: "${route.params.locale || 'en'}",
                currency: "EUR",
                environment: "${env}",
                device: "${device}",
                type: "WEBSITE",
                channel: "Endoscopy Product Configurator",
                division: "MSD"
              },
             page: {
                id: "${Tracking.getPageId(route)}",
                name: "${Tracking.getTrackingPageNames({ route, isPageName: true })}",
                hierarchy: [],
                hierarchyName: "${Tracking.getTrackingPageNames({ route })}",
                statusCode: 200,
                date: ""
             }
          };
          var trackingObject = OLY.trackingObject;
        `;

    if (appendToBody) {
      document.body.append(trackingScript);
    }
    return {};
  },

  getDevice() {
    if (window.outerWidth >= 992) {
      return 'DESKTOP';
    }

    if (window.outerWidth < 992 && window.outerWidth >= 768) {
      return 'TABLET';
    }

    return 'MOBILE';
  },

  getPageId(route) {
    return Tracking.getTrackingPageNames({ route }).replaceAll(' | ', '_').replaceAll(' ', '_');
  },

  updateTrackingObject(route) {
    if (!window.OLY?.trackingObject) {
      return;
    }

    window.OLY.trackingObject = {
      ...window.OLY.trackingObject,
      general: {
        ...window.OLY.trackingObject.general,
        language: route.params.locale
      },
      page: {
        id: Tracking.getPageId(route),
        name: Tracking.getTrackingPageNames({ route, isPageName: true }),
        hierarchy: [],
        hierarchyName: Tracking.getTrackingPageNames({ route }),
        statusCode: 200,
        date: ''
      }
    };
  },

  getTrackingPageNames({ route, isPageName = false }) {
    const globalDialogQuery = GLOBAL_DIALOG_QUERIES.find((query) => !!route.query[query]);

    if (globalDialogQuery) {
      return Tracking.buildTrackingPageName({
        route,
        withStep: isPageName,
        suffix: globalDialogQuery
      });
    }

    const actionQuery = CONFIGURATOR_ACTION_QUERIES.find((query) => !!route.query[query]);

    if (actionQuery) {
      return Tracking.buildTrackingPageName({
        route,
        name: route.name,
        withStep: true,
        suffix: actionQuery
      });
    }

    if (route.name === RouteName.CONFIGURATOR) {
      return Tracking.buildTrackingPageName({
        route,
        name: route.name,
        withStep: true,
        suffix: undefined
      });
    }

    return Tracking.buildTrackingPageName({
      route,
      name: RouteName.CONFIGURATOR,
      withStep: isPageName,
      suffix: route.name
    });
  },

  buildTrackingPageName({ route, name = route.name, withStep = false, suffix = '' }) {
    let pageTitle = name;

    if (withStep) {
      pageTitle += ` | Step ${String(route.query.step ?? 1)}`;
    }

    if (suffix) {
      pageTitle += ` | ${Tracking.buildCapitalizedQueryPhrase(suffix)}`;
    }

    return pageTitle;
  },

  buildCapitalizedQueryPhrase(key) {
    return key
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  },

  trackSuccess() {
    if (!window.OLY.trackingObject) {
      return;
    }

    window.OLY.trackingObject = {
      ...window.OLY.trackingObject,
      page: {
        ...window.OLY.trackingObject.page,
        name: window.OLY.trackingObject.page.name + ' | Success',
        hierarchyName: window.OLY.trackingObject.page.hierarchyName + ' | Success'
      }
    };
  }
};

export default Tracking;
