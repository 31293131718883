<template>
  <v-row justify="center" justify-sm="space-between">
    <trolley-with-product-images :trolley="trolley" :accessories="accessories" />

    <v-col cols="12" sm="7">
      <div class="zones__grid">
        <app-button
          v-for="(zone, index) in [...trolley.scopeZones, ...trolley.articleZones].filter(
            (zoneConfig) => !zoneConfig.isHidden
          )"
          :key="index"
          :class="{ article: true, 'article--disabled': !getSupportData(zone).isSupported }"
          block
          outlined
          color="primary"
          :disabled="!getSupportData(zone).isSupported"
          @click="startProductChange(zone)"
        >
          <template v-if="!getSupportData(zone).isSupported">
            <div class="zone__title zone__title--disabled">
              {{ $t(`optimize.zones.${zone.index}`) }}
            </div>

            <span class="caption">{{ getSupportData(zone).hint }}</span>
          </template>

          <template v-else>
            <div class="zone__title">
              {{ $t(`optimize.zones.${zone.index}`) }}
              <component
                :is="zone.articles.length > 0 ? 'icon-change' : 'icon-plus'"
                class="zone__icon"
              />
            </div>
            <ul class="article__info">
              <template v-if="isScopeZone(zone.type)">
                <li v-if="zone.articles.length > 0" class="article__number">
                  {{ $tc('optimize.selectedCounted', zone.articles.length) }}
                  <toggle-hide-button
                    v-if="zone.articles[0]"
                    :article-number="zone.articles[0].articleNumber"
                  />
                </li>
              </template>
              <template v-else>
                <li
                  v-for="article in zone.articles"
                  :key="article.articleNumber"
                  class="article__number"
                >
                  <span class="caption">{{ article.productCode }}</span>
                  <toggle-hide-button :article-number="article.articleNumber" />
                </li>
              </template>
            </ul>
          </template>
        </app-button>
      </div>

      <div class="links">
        <div v-if="esgSupported" class="link__container">
          <app-button
            text
            link
            icon="$iconArrowRight"
            :label="$t('optimize.viewESG')"
            @click="showChangeESGDialog = true"
          />
        </div>

        <div v-if="scopeGuide.scopeGuideAvailable" class="link__container">
          <app-button
            link
            icon="$iconArrowRight"
            :label="$t('optimize.scopeGuide')"
            @click="showScopeGuideDialog = true"
          />
          <app-radio
            :key="loadingScopeGuide"
            :disabled="loadingScopeGuide"
            :items="scopeGuideItems"
            :value="useScopeGuide"
            row
            @input="changeScopeGuideSelection"
          />
          <app-spinner v-if="loadingScopeGuide" class="link__spinner" size="30" />
        </div>
      </div>

      <change-scope-dialog v-model="showChangeScopeDialog" :current-zone="zoneToChange" />
      <change-article-dialog v-model="showChangeArticleDialog" :current-zone="zoneToChange" />
      <change-esg-dialog v-model="showChangeESGDialog" />
      <scope-guide-dialog v-model="showScopeGuideDialog" />
    </v-col>
  </v-row>
</template>

<script>
import Article from '@/statics/article';
import ArticleType from '@/statics/articleType';
import ChangeArticleDialog from '@/components/steps/3/ChangeArticle/ChangeArticleDialog';
import ChangeEsgDialog from '@/components/steps/3/ChangeArticle/ChangeEsgDialog';
import ChangeScopeDialog from '@/components/steps/3/ChangeArticle/ChangeScopeDialog';
import IconChange from '@/assets/icons/icon-change.svg';
import IconPlus from '@/assets/icons/icon-plus.svg';
import ProductImage from '@/components/ProductImage';
import ScopeGuideDialog from '@/components/steps/3/ChangeArticle/ScopeGuideDialog';
import ToggleHideButton from '@/components/steps/3/ToggleHideButton';
import TrolleyWithProductImages from '@/components/steps/3/TrolleyWithProductImages';
import Zone from '@/statics/zone';
import { checkSupport } from '@/helper/checkSupport';
import { findArticleInMappedPreset } from '@/helper/findArticleInMappedPreset';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'MainTrolley',

  components: {
    ChangeArticleDialog,
    ChangeEsgDialog,
    ChangeScopeDialog,
    IconChange,
    IconPlus,
    ProductImage,
    ScopeGuideDialog,
    ToggleHideButton,
    TrolleyWithProductImages
  },

  props: {
    trolley: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      zoneToChange: null,
      scopeGuideItems: [
        {
          label: this.$t('actions.yes'),
          value: true
        },
        { label: this.$t('actions.no'), value: false }
      ],
      showChangeArticleDialog: false,
      showChangeScopeDialog: false,
      showScopeGuideDialog: false,
      showChangeESGDialog: false,
      loadingScopeGuide: false
    };
  },

  computed: {
    ...mapState(['accessories', 'medicalSpeciality']),
    ...mapGetters(['scopeGuide', 'mappedPreset', 'selectedProcedureFocus']),
    useScopeGuide() {
      return this.scopeGuide.scopeGuideSelected;
    },
    esgSupported() {
      return this.getSupportData(this.trolley.articleZones.find((zone) => zone.index === Zone.M6))
        .isSupported;
    }
  },

  watch: {
    showChangeArticleDialog(dialogIsShown) {
      if (dialogIsShown) {
        return;
      }
      this.zoneToChange = null;
    },
    showChangeScopeDialog(dialogIsShown) {
      if (dialogIsShown) {
        return;
      }
      this.zoneToChange = null;
    }
  },

  methods: {
    ...mapActions(['addProducts', 'removeProducts']),

    getSupportData(zone) {
      return checkSupport({
        selectedMedicalSpeciality: this.medicalSpeciality,
        selectedProcedureFocus: this.selectedProcedureFocus,
        supportedMedicalSpeciality: zone.supportedMedicalSpeciality,
        supportedProcedureFocus: zone.supportedProcedureFocus,
        zoneIndex: zone.index
      });
    },

    startProductChange(zone) {
      this.zoneToChange = zone;

      if (zone.type === ArticleType.SCOPE) {
        this.showChangeScopeDialog = true;
        return;
      }

      this.showChangeArticleDialog = true;
    },

    isScopeZone(zoneType) {
      return zoneType === ArticleType.SCOPE;
    },

    async changeScopeGuideSelection(isSelected) {
      if (isSelected === this.useScopeGuide) {
        return;
      }

      this.loadingScopeGuide = true;
      if (isSelected) {
        const scopeGuideArticles = [Article.SCOPE_GUIDE];

        await this.addProducts(
          scopeGuideArticles.map((addedArticle) => ({
            zone: addedArticle.zone.index,
            trolley: addedArticle.zone.trolley,
            area: addedArticle.area,
            newArticleNumber: addedArticle.articleNumber
          }))
        );

        this.loadingScopeGuide = false;
        return;
      }

      const scopeGuideArticles = [
        Article.SCOPE_GUIDE,
        ...Article.SCOPE_GUIDE.requiredProducts.map((articleNumber) =>
          findArticleInMappedPreset(articleNumber, this.mappedPreset)
        )
      ];

      await this.removeProducts(
        scopeGuideArticles.map((removedArticle) => ({
          zone: removedArticle.zone.index,
          trolley: removedArticle.zone.trolley,
          area: removedArticle.area,
          oldArticleNumber: removedArticle.articleNumber
        }))
      );

      this.loadingScopeGuide = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/colors.scss';

$headerFontSize: calc(#{map-deep-get($headings, 'body-2', 'size')});

.v-application.theme--light .zones__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.75rem;

  ::v-deep .article.v-btn {
    align-items: flex-start;
    max-width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    border-width: $borderWidth;
    white-space: normal;

    &:first-of-type {
      grid-column: 1 / span 2;
    }

    > .v-btn__content {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      text-align: left;
      height: 100%;
    }

    .zone__title {
      @include body-2-font-style;
      word-break: break-word;
      hyphens: auto;
    }

    &.article--disabled {
      color: var(--v-text-base) !important;

      .zone__title {
        color: inherit;
      }
    }

    &:focus {
      background-color: var(--v-primary-base);
      color: var(--v-white-base);

      .zone__title,
      .article__number {
        color: var(--v-white-base);
      }
    }

    .zone__title {
      display: flex;
      justify-content: space-between;
      color: var(--v-primary-base);

      h3 {
        display: flex;
        align-items: center;
        white-space: normal;
        word-break: break-word;
        hyphens: auto;
      }

      .zone__icon {
        margin: 0 0 0 0.25rem;
        width: $headerFontSize;
        height: $headerFontSize;
        min-width: $headerFontSize;
        min-height: $headerFontSize;
        color: inherit;
      }
    }

    .article__info {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
    }

    .article__number {
      color: var(--v-text-base);
    }
  }
}

.links {
  margin-top: 2rem;
}

.link__container {
  background-color: rgba($accentLight, 0.6);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem 0;
  padding: 0.5rem 0.75rem;

  > * {
    flex-grow: 0;
    flex-shrink: 0;
  }

  ::v-deep {
    .button__label {
      text-decoration: underline;
    }

    .v-input--radio-group {
      margin-left: 1rem;
    }
  }
}

.link__spinner {
  flex: 0;
}
</style>
