<template>
  <v-dialog
    v-model="show"
    :style="cssVars"
    fullscreen
    no-click-animation
    persistent
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card v-if="value || fadeAnimationActive">
      <div class="dialog__title">
        <v-container class="d-flex flex-column">
          <div class="dialog__title-menu">
            <v-icon v-if="titleIcon" color="white" size="2rem">{{ titleIcon }}</v-icon>
            <app-button icon small outlined color="white" @click="close">
              <v-icon>mdi-close</v-icon>
            </app-button>
          </div>

          <slot name="title"></slot>
        </v-container>
      </div>

      <app-spinner v-if="loading" class="dialog__spinner" />

      <v-container v-else class="dialog__content-container">
        <div class="dialog__content">
          <slot></slot>
        </div>
        <action-bar
          v-if="!actionsHidden"
          id="dialog-actions"
          :action-text="actionText || $t('actions.confirm')"
          :action-icon="actionIcon"
          :loading="loading"
          nav-only
          @back="close"
          @next="submit"
        />
      </v-container>
    </v-card>

    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
  </v-dialog>
</template>

<script>
import ActionBar from '@/components/ActionBar';

export default {
  name: 'AppDialog',

  components: { ActionBar },

  props: {
    actionsHidden: { type: Boolean, default: false },
    actionIcon: { type: String, default: '' },
    actionText: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    queryParam: { type: String, default: '' },
    titleIcon: { type: String, default: '' },
    value: { type: Boolean, default: false }
  },

  data: () => ({
    actionsHeight: 0,
    fadeAnimationActive: false
  }),

  computed: {
    cssVars() {
      return { '--bottom-padding': this.actionsHeight + 'px' };
    },
    show: {
      get() {
        return this.value && !this.fadeAnimationActive;
      },
      set(value) {
        if (!value) {
          this.fadeAnimationActive = true;
          setTimeout(() => {
            this.$emit('input', false);
            this.fadeAnimationActive = false;
          }, 500);
        }
      }
    }
  },

  watch: {
    '$route.query'(query) {
      // toggle dialog on browser navigation
      if (!!this.show !== !!query[this.queryParam]) {
        this.$emit('input', !this.show);
      }
    },
    show(value) {
      // avoid duplicated navigation
      if (this.$route.query[this.queryParam] === String(value)) {
        return;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          [this.queryParam]: value || undefined
        }
      });
    }
  },

  created() {
    if (this.$route.query[this.queryParam]) {
      this.$emit('input', true);
    }
  },

  mounted() {
    this.setCssVars();
  },

  methods: {
    setCssVars() {
      this.actionsHeight = document.getElementById('dialog-actions')?.offsetHeight;
    },

    submit() {
      this.$emit('submit', true);
    },

    close() {
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1rem 1.5rem 2rem 1.5rem;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 2rem 1.5rem 2rem 3rem;
  }
}

.dialog__content-container {
  padding-bottom: calc(var(--bottom-padding) + 1rem);
}

.dialog__spinner {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog__content-container {
  flex: 1 1 auto;
  overflow-y: auto;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    ::v-deep .dialog__content {
      margin-right: 1.5rem;
    }
  }
}

.dialog__title {
  background-color: var(--v-primary-base);
  color: var(--v-white-base);
  flex: 0 0 auto;

  ::v-deep h2 {
    margin-top: 0.25rem;
  }
}

.dialog__title-menu {
  align-self: flex-end;

  > * {
    margin-left: 2rem;
  }
}
</style>
