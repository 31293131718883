<template>
  <ValidationProvider v-slot="{ errors }" :rules="rules" :name="name" mode="eager">
    <label class="black--text" :for="id" v-html="headlineWithAdditional"></label>
    <v-select
      v-model="selected"
      :class="{ 'input--error': errors.length > 0 }"
      :error-messages="errors"
      :items="items"
      :label="`${placeholder}${additional}`"
      :menu-props="{ offsetY: true, contentClass: 'select__menu' }"
      append-icon="$iconAngleDown"
      flat
      item-text="label"
      item-value="value"
      outlined
      solo
      v-bind="$attrs"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppSelect',

  mixins: [FormValidationMixin],

  props: {
    items: { type: Array, default: () => [] }
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/formInput.scss';

::v-deep {
  .v-select .v-icon__component {
    width: 1rem;
    height: 1rem;
    color: var(--v-primary-base);
  }
}
</style>
