import Area from '@/statics/area';

export default {
  props: {
    currentZone: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    newArticleNumbers: [],
    mainSelectableProducts: [],
    optionalSelectableProducts: [],
    loading: false,
    error: false
  }),

  computed: {
    valueNone() {
      // v-radio doesn't support empty strings as value
      return 'none';
    },
    currentProducts() {
      return this.currentZone?.articles ?? [];
    },
    selectableProducts() {
      return [...this.mainSelectableProducts, ...this.optionalSelectableProducts];
    },
    isMultiSelect() {
      return this.currentZone?.isMultiSelect && this.selectableProducts.length > 1;
    },
    addedProducts() {
      return this.newArticleNumbers
        .filter(
          (newArticleNumber) =>
            newArticleNumber !== this.valueNone &&
            !this.currentProducts.some(
              (currentArticle) => currentArticle.articleNumber === newArticleNumber
            )
        )
        .map((newArticleNumber) =>
          this.selectableProducts.find((article) => article.articleNumber === newArticleNumber)
        );
    },
    removedProducts() {
      return this.currentProducts.filter(
        (currentArticle) => !this.newArticleNumbers.includes(currentArticle.articleNumber)
      );
    }
  },

  methods: {
    async onSubmit() {
      if (this.removedProducts.length === 0 && this.addedProducts.length === 0) {
        this.close();
        return;
      }

      this.loading = true;

      const submitFunction = this.getSubmitFunction();
      const { error } = await submitFunction();

      this.loading = false;

      if (error) {
        return;
      }

      this.close();
    },

    getSubmitFunction() {
      if (this.addedProducts.length > 0 && this.removedProducts.length === 0) {
        return this.sendAddProducts;
      }

      if (this.addedProducts.length === 0 && this.removedProducts.length > 0) {
        return this.sendRemoveProducts;
      }

      if (this.addedProducts[0].isVideoProcessor && !this.isMultiSelect) {
        return this.sendSwitchVideoProcessor;
      }

      return this.sendSwitchProducts;
    },

    getSubmitDefaultPayload(article) {
      return {
        zone: article.zone?.index ?? this.currentZone.index,
        trolley: article.zone?.trolley ?? this.currentZone.trolley,
        area: article?.area ?? Area.DEFAULT
      };
    },

    async sendAddProducts() {
      return await this.addProducts(
        this.addedProducts.map((addedArticle) => ({
          ...this.getSubmitDefaultPayload(addedArticle),
          newArticleNumber: addedArticle.articleNumber
        }))
      );
    },

    async sendRemoveProducts() {
      return await this.removeProducts(
        this.removedProducts.map((removedArticle) => ({
          ...this.getSubmitDefaultPayload(removedArticle),
          oldArticleNumber: removedArticle.articleNumber
        }))
      );
    },

    async sendSwitchVideoProcessor() {
      return await this.switchVideoProcessor({
        newVideoProcessor: this.addedProducts[0].articleNumber
      });
    },

    async sendSwitchProducts() {
      return await this.switchProducts([
        ...this.removedProducts.map((removedArticle) => ({
          ...this.getSubmitDefaultPayload(removedArticle),
          oldArticleNumber: removedArticle.articleNumber
        })),
        ...this.addedProducts.map((addedArticle) => ({
          ...this.getSubmitDefaultPayload(addedArticle),
          newArticleNumber: addedArticle.articleNumber
        }))
      ]);
    },

    close() {
      this.$emit('input', false);
    }
  }
};
