import Range from '@/statics/range';
import Vue from 'vue';
import Vuex from 'vuex';
import showSnackbar from '@/helper/showSnackbar';
import {
  ProcedureType,
  ProcedureTypeSelectOptions,
  SelectedProcedureTypesMap
} from '@/statics/procedureType';
import { Trolley } from '@/statics/trolley';
import { buildConfigurationData } from '@/helper/buildConfigurationData';
import { filterArticlePropertiesInPreset } from '@/helper/filterArticlePropertiesInPreset';
import { getFlattenedProceduresList, mapProcedures } from '@/helper/procedures';
import { mapAccessories, mapPreset } from '@/helper/mapPreset';
import { withSorting } from '@/helper/withSorting';

Vue.use(Vuex);

const initialState = () => ({
  medicalSpeciality: '',
  selectedProcedureTypeOption: '',
  procedures: [],
  selectedProceduresIds: [],
  range: Range.INNOVATION,
  zones: [],
  preset: null,
  videoProcessor: null,
  overwriteDummyScope: null,
  accessories: [],
  selectedAccessoriesProductCodes: [],
  contactData: {
    customerName: '',
    customerEmail: '',
    customerPhoneNumber: '',
    customerCountry: '',
    customerCompany: '',
    customerQuestion: ''
  },
  formIsValid: true,
  error: false
});

export default new Vuex.Store({
  state: initialState(),

  getters: {
    articleNumbersInPreset(state) {
      if (!state.preset) {
        return;
      }

      return filterArticlePropertiesInPreset(['articleNumber'], state.preset);
    },

    filteredProcedures(state) {
      if (!state.selectedProcedureTypeOption) {
        return [];
      }

      const filteredProcedures = { ...state.procedures };

      Object.keys(filteredProcedures).forEach((key) => {
        if (!SelectedProcedureTypesMap[state.selectedProcedureTypeOption].includes(key)) {
          return delete filteredProcedures[key];
        }

        filteredProcedures[key] = withSorting(
          filteredProcedures[key].filter(
            (procedure) => procedure.medicalSpeciality === state.medicalSpeciality
          ),
          'name'
        );
      });

      return filteredProcedures;
    },

    selectedProcedures(state) {
      const allProcedures = getFlattenedProceduresList(state.procedures);

      return state.selectedProceduresIds
        .map((procedureId) => allProcedures.find((procedure) => procedure.id === procedureId))
        .filter(
          (procedure) => !!procedure /* null if stored procedure id does not match current ones */
        );
    },

    // focus of the actually selected procedures
    selectedProcedureFocus(state, getters) {
      return getters.selectedProcedures.some(
        (procedure) => procedure.type !== ProcedureType.DIAGNOSTIC
      )
        ? ProcedureTypeSelectOptions.ADVANCED
        : ProcedureTypeSelectOptions.GENERAL;
    },

    mappedPreset(state) {
      if (!state.preset) {
        return;
      }

      return mapPreset(state.preset, state.zones);
    },

    selectedAccessories(state) {
      return state.selectedAccessoriesProductCodes.map((productCode) =>
        state.accessories.find((accessory) => accessory.productCode === productCode)
      );
    },

    scopeGuide(state) {
      if (!state.preset) {
        return;
      }
      return state.preset[Trolley.SCOPE_GUIDE];
    }
  },

  mutations: {
    setMedicalSpeciality(state, medicalSpeciality) {
      state.medicalSpeciality = medicalSpeciality;
    },
    setProcedureTypeOption(state, selectedProcedureTypeOption) {
      state.selectedProcedureTypeOption = selectedProcedureTypeOption;
    },
    setProcedures(state, procedures) {
      state.procedures = procedures;
    },
    setRange(state, range) {
      state.range = range;
    },
    setSelectedProceduresIds(state, selectedProceduresIds) {
      state.selectedProceduresIds = selectedProceduresIds;
    },
    setZones(state, zones) {
      state.zones = zones;
    },
    setPreset(state, preset) {
      state.preset = preset;
      state.videoProcessor = preset[Trolley.MAIN]['videoProcessor'].articleNumber;
      state.overwriteDummyScope = preset.overwriteDummyScope;
    },
    setAccessories(state, accessories) {
      state.accessories = accessories;
    },
    setSelectedAccessoriesProductCodes(state, selectedAccessoriesProductCodes) {
      state.selectedAccessoriesProductCodes = selectedAccessoriesProductCodes;
    },
    setContactData(state, contactData) {
      state.contactData = contactData;
    },
    setFormIsValid(state, isValid) {
      state.formIsValid = isValid;
    },
    setError(state, value) {
      state.error = value;
    },
    restoreConfiguration(state, configurationData) {
      Object.keys(configurationData).forEach((key) => {
        state[key] = configurationData[key];
      });
    },
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async fetchProcedures({ commit }) {
      try {
        const { data } = await Vue.prototype.$http.get('/product-management/procedure');
        commit('setProcedures', mapProcedures(data));
      } catch (error) {
        commit('setError', true);
      }
    },

    async fetchZones({ commit }) {
      try {
        const { data } = await Vue.prototype.$http.get('/product-management/zone/list');
        commit('setZones', data);
      } catch (error) {
        commit('setError', true);
      }
    },

    async fetchPreset({ commit, state, getters }) {
      try {
        const { data } = await Vue.prototype.$http.get('/product-management/presets/search', {
          params: {
            medicalSpeciality: state.medicalSpeciality,
            procedureFocus: getters.selectedProcedureFocus,
            procedures: state.selectedProceduresIds,
            range: state.range
          }
        });

        commit('setPreset', data);

        const accessories = mapAccessories(data[Trolley.MAIN].accessory, state.zones);

        commit('setAccessories', accessories);
        commit(
          'setSelectedAccessoriesProductCodes',
          accessories.map((item) => item.productCode)
        );
      } catch (error) {
        commit('setError', true);
      }
    },

    async fetchScopes({ state, getters }, currentZone) {
      try {
        const { data } = await Vue.prototype.$http.post('product-management/filter/scope', {
          currentProducts: currentZone.articles.map((article) => article.articleNumber),
          medicalSpecialty: state.medicalSpeciality,
          products: getters.articleNumbersInPreset,
          videoProcessor: state.videoProcessor,
          zone: currentZone.index
        });

        return data.scopes;
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async fetchArticles({ state, getters }, currentZone) {
      try {
        const { data } = await Vue.prototype.$http.post('product-management/filter/article', {
          currentProducts: currentZone.articles.map((article) => article.articleNumber),
          medicalSpeciality: state.medicalSpeciality,
          procedureFocus: getters.selectedProcedureFocus,
          products: getters.articleNumbersInPreset,
          videoProcessor: state.videoProcessor,
          zone: currentZone.index
        });

        return data.article;
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async fetchArticlesInfo(_, articleNumbers) {
      try {
        const fetchMultiple = Array.isArray(articleNumbers);
        const articleNumbersAsString = fetchMultiple ? articleNumbers.join(',') : articleNumbers;

        const { data } = await Vue.prototype.$http.get(
          `product-management/article/list/${articleNumbersAsString}`
        );

        const articlesWithTrolleyData = data.map((article) => ({
          ...article,
          zone: {
            ...article.zone,
            trolley: article.zone.trolley.articleNumber
          }
        }));

        return fetchMultiple
          ? articleNumbers.map((articleNumber) => {
              return articlesWithTrolleyData.find(
                (article) => article.articleNumber === articleNumber
              );
            })
          : articlesWithTrolleyData[0];
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async addProducts({ state, commit }, addProducts) {
      try {
        const { data } = await Vue.prototype.$http.post('product-management/basket/add-product', {
          addProducts,
          preset: state.preset
        });

        commit('setPreset', data);
        return {};
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async removeProducts({ state, commit }, removeProducts) {
      try {
        const { data } = await Vue.prototype.$http.post(
          'product-management/basket/remove-product',
          {
            removeProducts,
            preset: state.preset
          }
        );

        commit('setPreset', data);
        return {};
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async switchVideoProcessor({ state, commit }, switchVideoProcessor) {
      try {
        const { data } = await Vue.prototype.$http.post(
          '/product-management/basket/switch-video-processor',
          {
            switchVideoProcessor,
            preset: state.preset
          }
        );

        commit('setPreset', data);
        return {};
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async switchProducts({ state, commit }, switchProducts) {
      try {
        const { data } = await Vue.prototype.$http.post(
          'product-management/basket/switch-product',
          {
            switchProducts,
            preset: state.preset
          }
        );

        commit('setPreset', data);
        return {};
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async sendContactRequest(_, contactData) {
      try {
        await Vue.prototype.$http.post('/product-management/contact-request/save', contactData);
        return {};
      } catch (error) {
        return { error };
      }
    },

    async submitConfiguration({ state, getters }) {
      try {
        const step = 3;
        await Vue.prototype.$http.post('product-management/configuration/confirm', {
          ...buildConfigurationData(state, getters),
          frontendDataSet: { state, step }
        });
        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchConfiguration({ commit }, configurationId) {
      try {
        const { data } = await Vue.prototype.$http.get(
          `/product-management/configuration/read/${configurationId}`
        );

        const { state, step } = data.payload.frontendDataSet;

        commit('restoreConfiguration', state);
        return { step };
      } catch (error) {
        return { error };
      }
    },

    async saveConfiguration({ state, getters }, { contactData, step }) {
      try {
        const response = await Vue.prototype.$http.post('product-management/configuration/save', {
          ...buildConfigurationData(state, getters),
          frontendDataSet: { state, step },
          contactData
        });
        return { webCode: response.data.webCode };
      } catch (error) {
        return { error };
      }
    }
  }
});
