<template>
  <router-view />
</template>

<script>
import Locale from '@/statics/locale';
import Localization from '@/helper/localization';
import Tracking from '@/helper/tracking';
import { localize } from 'vee-validate';

export default {
  name: 'App',

  computed: {
    oneTrustUrl() {
      return process.env.VUE_APP_ONE_TRUST_URL;
    }
  },

  watch: {
    $route() {
      if (!window.OLY) {
        Tracking.initiateTrackingObject(this.$route);
        return;
      }

      Tracking.updateTrackingObject(this.$route);
    }
  },

  metaInfo() {
    const metaTitle = this.$t('meta.title');
    const metaDescription = this.$t('meta.description');

    if (!Localization.initiated) {
      return;
    }

    return {
      title: metaTitle,
      htmlAttrs: {
        lang: Localization.currentLocale
      },
      meta: [
        { name: 'title', content: metaTitle },
        { name: 'description', content: metaDescription },
        { property: 'og:title', content: metaTitle },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:url',
          content: `${process.env.VUE_APP_BASE_URL}/${Localization.currentLocale}`
        },
        {
          property: 'og:image',
          content: process.env.VUE_APP_BASE_URL + '/assets/endoscopy-tower-configurator.png'
        },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: metaTitle },
        { name: 'twitter:description', content: metaDescription },
        {
          name: 'twitter:image',
          content: process.env.VUE_APP_BASE_URL + '/assets/endoscopy-tower-configurator.png'
        }
      ]
    };
  },

  created() {
    this.loadFonts();
  },

  mounted() {
    this.initiateLocaleChangeListener();
    this.initiateOneTrust();
  },

  methods: {
    initiateLocaleChangeListener() {
      this.$eventHub.$on('locale-changed', (locale) => {
        this.setLocale(locale);
      });

      this.$on('hook:beforeDestroy', () => {
        this.$eventHub.$off('locale-changed');
      });
    },

    setLocale(locale) {
      this.localizePlugins(locale);
    },

    localizePlugins(localeName) {
      const locale = Locale[localeName].primaryLocaleCode;

      import(`vee-validate/dist/locale/${locale}`).then((localeData) => {
        localize(locale, localeData);
      });

      import(`vuetify/lib/locale/${locale}.js`).then((localeData) => {
        this.$vuetify.lang.locales = {
          ...this.$vuetify.lang.locales,
          [locale]: localeData.default
        };
        this.$vuetify.lang.current = locale;
      });
    },

    loadFonts() {
      if (process.env.NODE_ENV === 'development') {
        import('@/styles/localFonts.scss');
        return;
      }

      import('@/styles/webFonts.scss');
    },

    initiateOneTrust() {
      const oneTrustInitiated = !!Array.from(document.getElementsByTagName('script')).find(
        (script) => script.src.includes(this.oneTrustUrl)
      );

      if (oneTrustInitiated) {
        return;
      }

      let oneTrustScript = document.createElement('script');
      oneTrustScript.async = true;
      oneTrustScript.src = this.oneTrustUrl;
      document.head.prepend(oneTrustScript);
    }
  }
};
</script>

<style lang="scss">
body {
  @include body-font-style;

  .theme--light {
    &.v-application {
      color: var(--v-primary-base);
      white-space: normal;

      h1 {
        font-size: map-deep-get($headings, 'h1', 'size');
        font-weight: map-deep-get($headings, 'h1', 'weight');
        line-height: map-deep-get($headings, 'h1', 'line-height');
        letter-spacing: map-deep-get($headings, 'h1', 'letter-spacing');
        font-family: map-deep-get($headings, 'h1', 'font-family');
      }

      h2 {
        font-size: map-deep-get($headings, 'h2', 'size');
        font-weight: map-deep-get($headings, 'h2', 'weight');
        line-height: map-deep-get($headings, 'h2', 'line-height');
        letter-spacing: map-deep-get($headings, 'h2', 'letter-spacing');
        font-family: map-deep-get($headings, 'h2', 'font-family');
      }

      h3 {
        font-size: map-deep-get($headings, 'h3', 'size');
        font-weight: map-deep-get($headings, 'h3', 'weight');
        line-height: map-deep-get($headings, 'h3', 'line-height');
        letter-spacing: map-deep-get($headings, 'h3', 'letter-spacing');
        font-family: map-deep-get($headings, 'h3', 'font-family');
      }

      .subtitle-1,
      .text-subtitle-1 {
        color: var(--v-secondary-base);
      }

      p,
      b {
        @include body-font-style;
      }

      p {
        margin-bottom: 0;
      }

      b {
        font-family: $fontFamilyBold;
      }

      .body-2,
      .text-body-2 {
        color: var(--v-primary-base);
      }

      .font-weight-bold {
        font-family: $fontFamilyBold !important;
      }

      .font-weight-regular {
        font-family: $fontFamilyRegular !important;
      }

      hr {
        opacity: 0.2;
        border: 1px solid var(--v-primary-base);
      }

      .container {
        max-width: 75rem;
      }

      .v-card,
      .v-sheet,
      .v-menu__content,
      .v-tooltip__content {
        border-radius: 0;
      }
    }
  }

  .select__menu {
    .v-input--checkbox {
      @include body-font-style;
    }

    .v-list-item {
      @include body-font-style;

      &.v-list-item:not(.v-list-item--disabled) {
        color: var(--v-primary-base) !important;
      }
    }
  }
}
</style>
