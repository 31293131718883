export const LocaleCode = {
  DE: 'de',
  EN: 'en',
  EN_GB: 'en-GB',
  ES: 'es',
  IT: 'it',
  NL: 'nl',
  RU: 'ru'
};

const mapLocale = (locale) => ({
  code: locale.code,
  domain: locale.domain ?? locale.code,
  title: locale.title ?? locale.code.toUpperCase(),
  primaryLocaleCode: locale.code.split('-')[0]
});

/**
 * @type {Object}
 * array of config objects with following properties
 * @property {String} code - the locales ISO code
 * @property {String} domain - domain of urls to external links in related language
 * @property {String} title - title in locale selection if chosen, default is lang in uppercase
 * @property {String} primaryLocaleCode - superior language code of locale (ISO 639-1)
 */
export const Locale = {
  [LocaleCode.DE]: mapLocale({ code: LocaleCode.DE }),
  [LocaleCode.EN]: mapLocale({
    code: LocaleCode.EN,
    domain: 'com',
    title: 'EN (US)'
  }),
  [LocaleCode.EN_GB]: mapLocale({
    code: LocaleCode.EN_GB,
    domain: 'co.uk',
    title: 'EN (UK)'
  }),
  [LocaleCode.ES]: mapLocale({ code: LocaleCode.ES }),
  [LocaleCode.IT]: mapLocale({ code: LocaleCode.IT }),
  [LocaleCode.NL]: mapLocale({ code: LocaleCode.NL }),
  [LocaleCode.RU]: mapLocale({ code: LocaleCode.RU })
};

export const LOCALES = Object.values(Locale);

export default Locale;
