<template>
  <div>
    <v-row align="end">
      <v-col cols="12" sm="6">
        <app-select
          id="customerCountry"
          :name="$t('formData.country')"
          :value="contactData.customerCountry"
          :items="$t('countryOptions')"
          :headline="$t('contactForm.countryHeadline')"
          :placeholder="$t('contactForm.country')"
          rules="required"
          @input="(value) => updateContactData('customerCountry', value)"
        />
      </v-col>
    </v-row>
    <v-row align="end">
      <v-col cols="12" sm="6">
        <app-text-field
          id="customerName"
          :name="$t('formData.customerName')"
          :value="contactData.customerName"
          :headline="$t('contactForm.customerNameHeadline')"
          :placeholder="$t('contactForm.customerName')"
          rules="required"
          @input="(value) => updateContactData('customerName', value)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <app-text-field
          id="customerCompany"
          :name="$t('formData.customerCompany')"
          :value="contactData.customerCompany"
          :headline="$t('contactForm.customerCompanyHeadline')"
          :placeholder="$t('contactForm.customerCompany')"
          rules="required"
          @input="(value) => updateContactData('customerCompany', value)"
        />
      </v-col>
    </v-row>
    <v-row align="end">
      <v-col cols="12" sm="6">
        <app-text-field
          id="email"
          :name="$t('formData.email')"
          :value="contactData.customerEmail"
          :headline="$t('contactForm.emailHeadline')"
          :placeholder="$t('contactForm.email')"
          rules="required|email"
          @input="(value) => updateContactData('customerEmail', value)"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <app-text-field
          id="customerPhoneNumber"
          :name="$t('formData.customerPhoneNumber')"
          :value="contactData.customerPhoneNumber"
          :headline="$t('contactForm.customerPhoneNumberHeadline')"
          :placeholder="$t('contactForm.customerPhoneNumber')"
          @input="(value) => updateContactData('customerPhoneNumber', value)"
        />
      </v-col>
    </v-row>
    <v-row align="end">
      <v-col cols="12">
        <app-textarea
          id="customerQuestion"
          :name="$t('formData.customerQuestion')"
          :value="contactData.customerQuestion"
          :headline="$t('contactForm.customerQuestionHeadline')"
          :placeholder="$t('contactForm.customerQuestion')"
          @input="(value) => updateContactData('customerQuestion', value)"
        />
      </v-col>
    </v-row>
    <v-row align="end">
      <v-col cols="8" sm="6">
        <span
          class="caption text--text"
          v-html="$t('contactForm.privacy', { link: privacyLink })"
        ></span>
      </v-col>
      <v-col cols="4" sm="6">
        <span class="caption text--text">
          {{ $t('contactForm.checkboxRequired') }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppSelect from '@/components/base/AppSelect.vue';
import AppTextField from '@/components/base/AppTextField.vue';
import AppTextarea from '@/components/base/AppTextarea.vue';
import FooterUrls from '@/statics/urls';
import Localization from '@/helper/localization';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'SubmitContactForm',

  components: { AppTextField, AppSelect, AppTextarea },

  computed: {
    ...mapState(['contactData']),

    privacyLink() {
      return `
        <a href="${Localization.buildUrl(
          FooterUrls.PRIVACY_NOTICE
        )}" target="_blank" class="primary--text">${this.$t('footerUrlsLabel.privacyNotice')}</a>
      `;
    }
  },

  methods: {
    ...mapMutations(['setContactData']),
    updateContactData(field, value) {
      const updated = { ...this.contactData };
      updated[field] = value;

      this.setContactData(updated);
    }
  }
};
</script>
