<template>
  <div class="spinner">
    <v-progress-circular :size="size" color="primary" indeterminate />
  </div>
</template>

<script>
export default {
  name: 'AppSpinner',

  props: {
    size: {
      type: [Number, String],
      default: 120
    }
  }
};
</script>

<style scoped lang="scss">
.spinner {
  width: 100%;
  text-align: center;
}
</style>
