<template>
  <v-app v-resize="setCssVars" class="app-container" :style="cssVars">
    <app-header />
    <stepper-progress-bar v-if="$route.meta.withProgressBar" />
    <v-main>
      <router-view />
      <app-snackbar />
    </v-main>
    <app-footer />
  </v-app>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader';
import AppFooter from '@/components/layout/AppFooter';
import AppSnackbar from '@/components/base/AppSnackbar';
import StepperProgressBar from '@/components/StepperProgressBar';

export default {
  name: 'Layout',

  components: { StepperProgressBar, AppHeader, AppFooter, AppSnackbar },

  data: () => ({
    actionsHeight: 0
  }),

  computed: {
    cssVars() {
      return { '--bottom-padding': this.actionsHeight + 'px' };
    }
  },

  mounted() {
    this.setCssVars();
  },

  methods: {
    setCssVars() {
      this.actionsHeight = document.getElementById('bottom-navigation')?.offsetHeight;
    }
  }
};
</script>

<style scoped lang="scss">
.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: var(--bottom-padding);

  ::v-deep {
    header {
      flex: 0 0 auto;
      position: sticky;
      top: 0;
      @include z-index(header);
    }

    .v-main {
      flex: 1 0 auto;

      .v-main__wrap {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
