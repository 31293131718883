var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',_vm._g({attrs:{"loading":_vm.loading,"query-param":_vm.queryParam,"value":_vm.value},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('changeArticle.subline')))]),_c('h2',[_vm._v(_vm._s(_vm.$t('changeArticle.headline')))])]},proxy:true}])},_vm.$listeners),[(_vm.selectableProducts.length === 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('changeArticle.noArticlesAvailable'))+" ")]):_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.filteredProducts,"no-data-text":_vm.$t('changeArticle.noScopesAvailable')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('app-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"selection"},_vm.selectedProducts.length > 0 ? on : null),[_c('b',{staticClass:"selection__counter"},[_vm._v(" "+_vm._s(_vm.$tc('changeArticle.scopesSelectedCounted', _vm.selectedProducts.length))+" ")]),(_vm.selectedProducts.length > 0)?_c('icon-info',{staticClass:"selection__info-icon"}):_vm._e()],1)]}}])},[_c('b',[_vm._v(_vm._s(_vm.$t('changeArticle.currentlySelected')))]),_c('ul',{staticClass:"selection__list"},_vm._l((_vm.selectedProducts),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item.productCode)+" "),(item.technologyData['Scope type'])?[_vm._v(" ("+_vm._s(item.technologyData['Scope type'])+") ")]:_vm._e()],2)}),0)]),_c('scope-filters',{attrs:{"selectable-products":_vm.selectableProducts},model:{value:(_vm.filteredProducts),callback:function ($$v) {_vm.filteredProducts=$$v},expression:"filteredProducts"}})]},proxy:true},{key:"header",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header)+" ")]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var item = ref.item;
return [_c('app-checkbox',{attrs:{"item-value":item.articleNumber},model:{value:(_vm.newArticleNumbers),callback:function ($$v) {_vm.newArticleNumbers=$$v},expression:"newArticleNumbers"}})]}},{key:"header.data-table-select",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }