<template>
  <v-alert
    :icon="icon || false"
    class="alert font-weight-bold"
    :color="color"
    text
    outlined
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  name: 'AppAlert',

  props: {
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: [String, Boolean],
      default: ''
    }
  }
};
</script>

<style scoped lang="scss">
.alert {
  @include body-font-style;
  padding: calc(1rem + #{$fontVerticalOffsetBody2}) 1rem 1rem 1rem;
  margin: 0;

  ::v-deep .v-icon {
    color: inherit;
    margin-top: -$fontVerticalOffsetBody2;
  }
}
</style>
