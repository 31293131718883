export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    headline: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },

  computed: {
    headlineWithAdditional() {
      if (this.additional === '*') {
        return `${this.headline}${this.additional}`;
      }

      return this.headline;
    },

    additional() {
      if (this.rules.includes('required')) {
        return '*';
      }
      return ` (${this.$t('formData.optional')})`;
    }
  }
};
