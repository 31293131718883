<template>
  <app-tooltip>
    <template #activator="{ on }">
      <div class="requirements__activator" v-on="requirements.length > 0 ? on : null">
        <slot :disabled="requirements.length > 0"></slot>
      </div>
    </template>

    <b>{{ headline }}</b>
    <ul class="requirements__list">
      <li v-for="(item, index) in requirements" :key="index">
        {{ item }}
      </li>
    </ul>
  </app-tooltip>
</template>

<script>
import AppTooltip from '@/components/base/AppTooltip';

export default {
  name: 'RequirementsTooltip',

  components: { AppTooltip },

  props: {
    headline: {
      type: String,
      default: ''
    },
    requirements: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped lang="scss">
.requirements__activator {
  display: inline-block;
}

.requirements__list {
  margin-top: 0.5rem;
}
</style>
