import Article from '@/statics/article';
import i18n from '@/plugins/i18n';

// in a weak check only at least one condition has to be fulfilled, else all
const WEAK_ZONES = ['M6'];

const WEAK_ARTICLES = [
  Article.ESG_150.articleNumber,
  Article.ESG_300.articleNumber,
  Article.APU_300.articleNumber
];

/**
 * @typedef {Object} SupportInfo
 * @property {boolean} isSupported - article/zone is supported for selected medical speciality and procedure focus
 * @property {string} [hint] - info to show in template
 */

/**
 * check if article or zone is supported for selected medical speciality and procedures
 * @param {Object} data
 * @param  {'Gastroenterology' | 'Pulmonology'} data.selectedMedicalSpeciality
 * @param  {'diagnostic' | 'diagnosticAndTherapeutic'} data.selectedProcedureFocus
 * @param  {'Gastroenterology' | 'Pulmonology' | null} data.supportedMedicalSpeciality - if null, all are supported
 * @param {'diagnostic' | 'diagnosticAndTherapeutic' | null} data.supportedProcedureFocus - if null, all are supported
 * @param {string} [data.articleNumber] - article number, to enable weak checks for articles
 * @param {string} [data.zoneIndex] - zone index, to enable weak checks for zone
 * @returns {SupportInfo}
 */
export const checkSupport = ({
  selectedMedicalSpeciality,
  selectedProcedureFocus,
  supportedMedicalSpeciality,
  supportedProcedureFocus,
  articleNumber,
  zoneIndex
}) => {
  const supportsMedicalSpeciality =
    !supportedMedicalSpeciality || supportedMedicalSpeciality === selectedMedicalSpeciality;
  const supportsProcedureFocus =
    !supportedProcedureFocus || selectedProcedureFocus === supportedProcedureFocus;

  const useWeakCheck = WEAK_ZONES.includes(zoneIndex) || WEAK_ARTICLES.includes(articleNumber);

  const isSupported = useWeakCheck
    ? supportsMedicalSpeciality || supportsProcedureFocus
    : supportsMedicalSpeciality && supportsProcedureFocus;

  let response = {
    isSupported: true,
    hint: ''
  };

  if (!isSupported) {
    response.isSupported = false;
  }

  // so far only unsupported medical speciality needs hints
  // unsupported procedure focus only affects visibility of HF generators
  if (!supportsMedicalSpeciality) {
    response.hint = i18n.t('optimize.supportHintMedicalSpeciality', {
      supported: i18n.t(`gettingStarted.medicalSpeciality.${supportedMedicalSpeciality}`)
    });
  }

  return response;
};
