<template>
  <div :class="{ 'locale-changer': true, dark: menu }">
    <app-button
      text
      :color="menu ? 'white' : 'primary'"
      v-on="!menu ? { click: toggleMenu } : null"
    >
      <icon-globe class="button__icon" />
      {{ selectedLocaleTitle }}
      <icon-angle-down class="button__icon small ml-2 mr-0" />
    </app-button>
    <v-expand-transition>
      <v-list v-if="menu" v-click-outside="toggleMenu" dark tag="ul">
        <v-list-item-group v-model="selectedLocaleCode" mandatory>
          <v-list-item
            v-for="locale in localeItems"
            :key="locale.value"
            tag="li"
            dense
            :value="locale.value"
            class="caption"
          >
            {{ locale.label }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-expand-transition>
  </div>
</template>

<script>
import IconAngleDown from '@/assets/icons/icon-angle-down.svg';
import IconGlobe from '@/assets/icons/icon-globe.svg';
import Localization from '@/helper/localization';
import { LOCALES } from '@/statics/locale';
import { withSorting } from '@/helper/withSorting';

export default {
  name: 'LocaleChanger',

  components: { IconAngleDown, IconGlobe },

  data() {
    return {
      menu: false
    };
  },

  computed: {
    selectedLocaleCode: {
      get() {
        return Localization.currentLocale;
      },
      set(locale) {
        Localization.changeLocale(locale).then(() => {
          if (this.$route.params.locale === locale) {
            return;
          }

          this.$router.push({ ...this.$route, params: { locale: locale } });
        });
      }
    },
    selectedLocale() {
      return LOCALES.find((locale) => locale.code === this.selectedLocaleCode);
    },
    localeItems() {
      return withSorting(
        LOCALES.map((locale) => ({
          label: this.$t(`locale.${locale.code}`),
          value: locale.code
        })),
        'label'
      );
    },
    selectedLocaleTitle() {
      return this.selectedLocale.title;
    }
  },

  watch: {
    selectedLocaleCode() {
      this.toggleMenu();
    }
  },

  methods: {
    toggleMenu() {
      this.menu = !this.menu;
    }
  }
};
</script>

<style scoped lang="scss">
.locale-changer {
  position: relative;

  &.dark {
    background-color: var(--v-black-base);
    color: var(--v-white-base);
  }

  .v-sheet.v-list {
    background-color: inherit;
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 0;

    .v-list-item--active {
      color: var(--v-secondary-base);

      &::before {
        opacity: 0;
      }
    }
  }
}
</style>
