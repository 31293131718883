<template>
  <div class="trolley__info">
    <b class="trolley__description">
      {{ $t('optimize.ghostDescription') }}
    </b>

    <ul class="trolley__articles">
      <template v-for="(article, index) in articlesFlatList">
        <li :key="article.articleNumber" class="article__item">
          <v-row align="center" justify="space-between">
            <v-col cols="5" md="4" lg="3">
              <product-image
                :article="article"
                max-width="7.5rem"
                height="5rem"
                contain
                class="trolley__image"
              />
            </v-col>
            <v-col cols="7" class="col--indented py-4">
              {{ article.title || article.productCode }}
              <toggle-hide-button :article-number="article.articleNumber" />
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndUp" cols="7" offset="5" class="col--indented">
              <hr v-if="index + 1 < articlesFlatList.length" :key="'divider_' + index" />
            </v-col>
          </v-row>
          <hr
            v-if="!$vuetify.breakpoint.smAndUp && index + 1 < articlesFlatList.length"
            :key="'divider_' + index"
            class="my-4"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import ProductImage from '@/components/ProductImage';
import ToggleHideButton from '@/components/steps/3/ToggleHideButton';

export default {
  name: 'GhostTrolley',

  components: { ProductImage, ToggleHideButton },

  props: {
    trolley: {
      type: Object,
      required: true
    }
  },

  computed: {
    articlesFlatList() {
      return this.trolley.articleZones.reduce(
        (articleList, zone) => [...articleList, ...zone.articles],
        []
      );
    }
  }
};
</script>

<style scoped lang="scss">
.trolley__info {
  color: var(--v-text-base);

  .trolley__description {
    margin-top: 1rem;
    display: inline-block;
  }

  .trolley__articles {
    margin-top: 3rem;
    padding: 0;
    list-style-type: none;
  }

  .trolley__image {
    margin: 0 auto;
  }
}

.row {
  margin: 0;

  > * {
    padding: 0;
  }
}

.col--indented {
  padding-left: 0.75rem;
}
</style>
