<template>
  <div v-if="article" class="article-info">
    <app-alert
      v-if="article.changeHint && showChangeHint"
      icon="$iconInfo"
      color="primary"
      class="article-info__hint"
    >
      {{ article.changeHint }}
    </app-alert>

    <app-alert
      v-if="article.countryHint"
      icon="$iconInfo"
      color="primary"
      class="article-info__hint"
    >
      {{ article.countryHint }}
    </app-alert>

    <b class="article-info__title">{{ article.title || article.name }}</b>
    <div>{{ article.description }}</div>

    <template v-if="article.keyFacts.length > 0">
      <hr />
      <ul class="article-key-facts__list">
        <li
          v-for="(keyFact, index) in article.keyFacts"
          :key="index"
          class="article-key-facts__list-item"
        >
          <icon-check class="article-key-facts__icon" />
          {{ keyFact }}
        </li>
      </ul>
    </template>

    <template v-if="Object.keys(article.technologyData).length > 0">
      <hr />
      <div class="caption">
        <span class="font-weight-bold">{{ $t('changeArticle.technicalSpecifications') }}</span>
        <ul class="article-technical-data__list">
          <li
            v-for="([dataKey, dataValue], index) in Object.entries(article.technologyData)"
            :key="index"
            class="article-technical-data__list-item"
          >
            <span class="article-technical-data__list-item-key font-weight-regular">
              {{ dataKey }}:
            </span>
            {{ dataValue }}
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import AppAlert from '@/components/base/AppAlert';
import IconCheck from '@/assets/icons/icon-check.svg';

export default {
  name: 'ArticleInfo',

  components: { AppAlert, IconCheck },

  props: {
    article: {
      type: Object,
      required: true
    },
    showChangeHint: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
hr,
ul {
  margin: 2rem 0;
}

.article-info {
  white-space: pre-line;
}

.article-info__hint {
  margin-bottom: 1.5rem;
}

.article-info__title {
  margin-bottom: 1rem;
  display: inline-block;
}

.article-key-facts__list,
.article-technical-data__list {
  list-style-type: none;
  padding: 0;
}

.article-key-facts__list-item,
.article-technical-data__list-item {
  padding: 0.5rem 0;
}

.article-key-facts__list-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.article-key-facts__icon {
  height: map-deep-get($headings, 'body-1', 'size');
  flex: 0 0 auto;
  color: var(--v-primary-base);
  margin-right: 1rem;
}

.article-technical-data__list {
  margin-top: 0.5rem;

  .article-technical-data__list-item-key {
    margin-right: 1rem;
  }
}
</style>
