<template>
  <app-dialog
    :actions-hidden="submitted"
    :loading="loading"
    :query-param="queryParam"
    :value="value"
    :action-text="$t('actions.sendRequest')"
    title-icon="$iconMail"
    v-bind="$attrs"
    v-on="$listeners"
    @submit="onSubmit"
  >
    <template v-slot:title>
      <span class="subtitle-1 font-weight-bold">{{ $t('contact.subline') }}</span>
      <h2>{{ $t('contact.headline') }}</h2>
    </template>

    <div v-if="submitted" class="success__container">
      <h2>
        <icon-check class="success__icon" />
        {{ $t('success.headline') }}
      </h2>
      <h3 class="area__subline" v-html="$t('contact.success')"></h3>
      <app-button
        class="success__button"
        color="secondary"
        :label="$t('contact.backToConfiguration')"
        @click="onClose"
      />
    </div>

    <template v-else>
      <h3 class="area__subline primary--text">{{ $t('contact.info') }}</h3>

      <v-row align="start" justify="center" justify-sm="space-between">
        <v-col cols="12" sm="7" md="7">
          <app-alert v-if="error" color="error" class="form__alert">
            {{ $t('error.alert') }}
          </app-alert>

          <ValidationObserver ref="contactForm" tag="form" @submit.prevent="onSubmit">
            <app-textarea
              id="customerQuestion"
              v-model="formData.customerQuestion"
              :name="$t('formData.customerQuestion')"
              :headline="$t('contact.customerQuestionHeadline')"
              :placeholder="$t('contactForm.customerQuestion')"
              rules="required"
            />
            <app-select
              id="countryCode"
              v-model="formData.customerCountry"
              :name="$t('formData.country')"
              :items="$t('countryOptions')"
              :headline="$t('contactForm.countryHeadline')"
              :placeholder="$t('contactForm.country')"
              rules="required"
            />
            <app-text-field
              id="customerName"
              v-model="formData.customerName"
              :name="$t('formData.customerName')"
              :headline="$t('contactForm.customerNameHeadline')"
              :placeholder="$t('contactForm.customerName')"
              rules="required"
            />
            <app-text-field
              id="organization"
              v-model="formData.customerCompany"
              :name="$t('formData.customerCompany')"
              :headline="$t('contactForm.customerCompanyHeadline')"
              :placeholder="$t('contactForm.customerCompany')"
              rules="required"
            />
            <app-text-field
              id="email"
              v-model="formData.customerEmail"
              :name="$t('formData.email')"
              :headline="$t('contactForm.emailHeadline')"
              :placeholder="$t('contactForm.email')"
              rules="required|email"
            />
            <app-text-field
              id="phone"
              v-model="formData.customerPhoneNumber"
              :name="$t('formData.customerPhoneNumber')"
              :headline="$t('contactForm.customerPhoneNumberHeadline')"
              :placeholder="$t('contactForm.customerPhoneNumber')"
            />
            <v-row>
              <v-col cols="8">
                <span
                  class="caption text--text"
                  v-html="$t('contactForm.privacy', { link: privacyLink })"
                ></span>
              </v-col>
              <v-col cols="4">
                <span class="caption text--text">
                  {{ $t('contactForm.checkboxRequired') }}
                </span>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-col>
        <v-col cols="5" md="3" class="d-flex align-center">
          <v-img src="@/assets/trolley.png" class="trolley-asset" />
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppAlert from '@/components/base/AppAlert';
import AppSelect from '@/components/base/AppSelect';
import AppTextField from '@/components/base/AppTextField';
import AppTextarea from '@/components/base/AppTextarea';
import FooterUrls from '@/statics/urls';
import IconCheck from '@/assets/icons/icon-check.svg';
import Localization from '@/helper/localization';
import Tracking from '@/helper/tracking';
import { GlobalDialogQuery } from '@/statics/dialogQueries';
import { mapActions } from 'vuex';

export default {
  name: 'ContactDialog',

  components: { AppAlert, AppTextField, AppSelect, AppTextarea, IconCheck },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    error: false,
    loading: false,
    submitted: false,
    formData: {
      customerName: '',
      customerEmail: '',
      customerPhoneNumber: '',
      customerCountry: '',
      customerCompany: '',
      customerQuestion: ''
    },
    queryParam: GlobalDialogQuery.CONTACT
  }),

  computed: {
    privacyLink() {
      return `
        <a href="${Localization.buildUrl(
          FooterUrls.PRIVACY_NOTICE
        )}" target="_blank" class="primary--text">${this.$t('footerUrlsLabel.privacyNotice')}</a>
      `;
    }
  },

  watch: {
    value(value) {
      if (!value) {
        this.resetForm();
      }
    }
  },

  methods: {
    ...mapActions(['sendContactRequest']),

    async onSubmit() {
      this.error = false;
      if (!(await this.$refs.contactForm.validate())) {
        this.scrollToFaultyInput();
        return;
      }

      this.loading = true;
      const { error } = await this.sendContactRequest(this.formData);
      this.loading = false;

      if (error) {
        this.error = true;
        return;
      }

      this.submitted = true;
      Tracking.trackSuccess();
    },

    onClose() {
      this.$emit('input', false);
    },

    scrollToFaultyInput() {
      this.$vuetify.goTo(document.querySelector('.input--error'), {
        duration: 500,
        offset: 50,
        easing: 'easeInOutCubic',
        container: '.dialog__content-container'
      });
    },

    resetForm() {
      this.error = false;
      this.loading = false;
      this.submitted = false;
      this.formData = {
        customerName: '',
        customerEmail: '',
        customerPhoneNumber: '',
        customerCountry: '',
        customerCompany: '',
        customerQuestion: ''
      };
    }
  }
};
</script>

<style scoped lang="scss">
.trolley-asset {
  margin-top: 2rem;
}

.success__container {
  color: var(--v-primary-base);

  @media #{map-get($display-breakpoints, 'xs-only')} {
    text-align: center;
  }
}

.success__icon {
  color: var(--v-success-base);
  height: map-deep-get($headings, 'h2', 'size');

  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: block;
    margin: 2rem auto 2rem auto;
    height: 4rem;
  }
}

.success__button {
  float: right;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    float: unset;
  }
}

.area__subline {
  margin: 1.5rem 0 2rem;
}

.form__alert {
  margin-bottom: 1.5rem;
}
</style>
