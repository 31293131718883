<template>
  <div>
    <v-row align="center">
      <v-col cols="4" sm="auto">
        <product-image :article="accessory" width="7.5rem" height="7.5rem" contain />
      </v-col>
      <v-col cols="8" sm="5">
        <div class="font-weight-bold text--text">{{ accessory.title }}</div>
        <div v-if="accessory.description !== ''" class="text--text">
          {{ accessory.description }}
        </div>
      </v-col>
      <v-spacer />
      <v-col cols="auto" offset="4" offset-sm="0">
        <app-radio v-model="selected" class="accessory-item__radio" row :items="choiceItems" />
      </v-col>
    </v-row>
    <v-divider v-if="!isLastOne" class="my-4" />
  </div>
</template>

<script>
import ProductImage from '@/components/ProductImage';

export default {
  name: 'AccessoryListItem',

  components: { ProductImage },

  props: {
    accessory: { type: Object, default: () => ({}) },
    isSelected: { type: Boolean, default: false },
    isLastOne: { type: Boolean, default: false }
  },

  data() {
    return {
      choiceItems: [
        {
          label: this.$t('actions.yes'),
          value: true
        },
        { label: this.$t('actions.no'), value: false }
      ]
    };
  },

  computed: {
    selected: {
      get() {
        return this.isSelected;
      },
      set() {
        this.$emit('input');
      }
    }
  }
};
</script>

<style scoped lang="scss">
.accessory-item__radio ::v-deep .v-radio:not(:first-of-type) {
  margin-left: 1rem;
}
</style>
