<template>
  <app-dialog
    :loading="loading"
    :query-param="queryParam"
    :value="value"
    v-on="$listeners"
    @submit="onSubmit"
  >
    <template v-slot:title>
      <span class="subtitle-1 font-weight-bold">{{ $t('changeArticle.subline') }}</span>
      <h2>{{ $t('changeArticle.headline') }}</h2>
    </template>

    <p v-if="selectableProducts.length === 0">
      {{ $t('changeArticle.noArticlesAvailable') }}
    </p>

    <v-data-table
      v-else
      show-select
      :headers="headers"
      :items="filteredProducts"
      :no-data-text="$t('changeArticle.noScopesAvailable')"
    >
      <template v-slot:top>
        <app-tooltip>
          <template #activator="{ on }">
            <div class="selection" v-on="selectedProducts.length > 0 ? on : null">
              <b class="selection__counter">
                {{ $tc('changeArticle.scopesSelectedCounted', selectedProducts.length) }}
              </b>
              <icon-info v-if="selectedProducts.length > 0" class="selection__info-icon" />
            </div>
          </template>

          <b>{{ $t('changeArticle.currentlySelected') }}</b>
          <ul class="selection__list">
            <li v-for="(item, index) in selectedProducts" :key="index">
              {{ item.productCode }}
              <template v-if="item.technologyData['Scope type']">
                ({{ item.technologyData['Scope type'] }})
              </template>
            </li>
          </ul>
        </app-tooltip>
        <scope-filters v-model="filteredProducts" :selectable-products="selectableProducts" />
      </template>
      <template v-slot:header="{ header }">
        {{ header }}
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select, item }">
        <app-checkbox v-model="newArticleNumbers" :item-value="item.articleNumber" />
      </template>

      <template v-slot:header.data-table-select><!-- disable select-all in header --></template>
    </v-data-table>
  </app-dialog>
</template>

<script>
import AppTooltip from '@/components/base/AppTooltip';
import ChangeProductsMixin from '@/mixins/ChangeProductsMixin';
import IconInfo from '@/assets/icons/icon-info.svg';
import ScopeFilters from '@/components/steps/3/ChangeArticle/ScopeFilters';
import { ConfiguratorActionQuery } from '@/statics/dialogQueries';
import { mapActions } from 'vuex';
import { withSorting } from '@/helper/withSorting';

export default {
  name: 'ChangeScopeDialog',

  components: { AppTooltip, IconInfo, ScopeFilters },

  mixins: [ChangeProductsMixin],

  data: () => ({
    filteredProducts: [],
    queryParam: ConfiguratorActionQuery.CHANGE_SCOPE
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t('changeArticle.productCode'),
          value: 'productCode'
        },
        ...Object.keys(this.selectableProducts[0]?.technologyData ?? {})
          .slice(1)
          .map((key) => ({
            text: key,
            value: `technologyData.${key}`
          }))
      ];
    },
    selectedProducts() {
      return this.newArticleNumbers.map((articleNumber) =>
        this.selectableProducts.find((item) => item.articleNumber === articleNumber)
      );
    }
  },

  watch: {
    async value() {
      if (!this.value) {
        this.resetData();
        return;
      }

      this.loading = true;
      const fetchedScopes = await this.fetchScopes(this.currentZone);
      const currentArticleNumbers = (this.currentZone?.articles ?? []).map(
        (article) => article.articleNumber
      );

      this.mainSelectableProducts =
        this.currentZone.articles.length === 0
          ? fetchedScopes
          : [
              ...withSorting(
                this.currentZone.articles.map((article) => ({
                  ...article,
                  productCode: article.title
                })),
                'productCode'
              ),
              ...withSorting(
                fetchedScopes.filter(
                  (item) =>
                    !this.currentZone.articles.some(
                      (currentArticle) => currentArticle.articleNumber === item.articleNumber
                    )
                ),
                'productCode'
              )
            ];
      this.newArticleNumbers = currentArticleNumbers.length > 0 ? [...currentArticleNumbers] : [];

      this.loading = false;
    }
  },

  methods: {
    ...mapActions(['fetchScopes', 'addProducts', 'removeProducts', 'switchProducts']),

    resetData() {
      this.mainSelectableProducts = [];
      this.newArticleNumbers = [];
      this.loading = false;
      this.error = false;
    }
  }
};
</script>

<style scoped lang="scss">
.selectable-scopes__divider {
  margin: 0.5rem 0;
}

.theme--light.v-data-table ::v-deep {
  // table
  > .v-data-table__wrapper > table {
    @include body-font-style;

    // table row
    tr {
      &.v-data-table__empty-wrapper {
        font-family: $fontFamilyBold;
        color: var(--v-primary-base);
        text-align: left;
      }

      > td:nth-child(2) {
        white-space: nowrap;
      }
    }

    // table and header cells
    > tbody > tr > td,
    > thead th {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      padding: 1rem;
    }

    > thead th {
      vertical-align: bottom;
      padding-bottom: 0;

      > span {
        display: block;
      }
    }

    td .v-input--checkbox {
      display: block;
    }
  }

  .v-data-table-header-mobile__select {
    display: none;
  }
}

.selection {
  display: inline-flex;
}

.selection__counter {
  margin-right: 0.5rem;
}

.selection__info-icon {
  display: inline-block;
  height: map-deep-get($headings, 'body-1', 'size');
  margin-top: $fontVerticalOffsetBody1;
}

.selection__list {
  margin-top: 0.5rem;
}
</style>
