/**
 * finds articles (excluding scopes!) in the mapped preset
 * @param searchedArticleNumber {String} - articleNumber of the article to look for
 * @param mappedPreset {Array} - current mapped preset configuration
 * @returns {Object | undefined} - data of the matched article
 */
export const findArticleInMappedPreset = (searchedArticleNumber, mappedPreset) => {
  return mappedPreset.reduce((foundEntry, trolley) => {
    if (foundEntry) {
      return foundEntry;
    }

    const foundArticle = trolley.articleZones
      .find((zone) =>
        zone.articles.find((article) => article.articleNumber === searchedArticleNumber)
      )
      ?.articles.find((article) => article.articleNumber === searchedArticleNumber);

    if (!foundArticle) {
      return;
    }

    // set correct trolley, important on ghost products
    return {
      ...foundArticle,
      zone: { ...foundArticle.zone, trolley: trolley.type }
    };
  }, null);
};
