import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Localization from '@/helper/localization';

export const getBaseUrl = () => `${process.env.VUE_APP_OL_API_URL}/${Localization.currentLocale}`;

const instance = axios.create({ baseURL: getBaseUrl() });

instance.interceptors.request.use(
  async (config) => ({
    ...config,
    baseURL: getBaseUrl()
  }),
  (error) => Promise.reject(error)
);

Vue.use(VueAxios, instance);
